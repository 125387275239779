import Tab from "../../../components/Tab/Tab";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {tooltipMap} from "../../MultiTenantDashboard/config/graph-config";
import TooltipIcon from "../../../assets/icons/tooltip.svg";
import {mapVulnerabilitiesChartData} from "../../../util/mapChartData"
import BarChart from "../../../components/D3/BarChart/BarChart";
import React, {useEffect, useState, useRef} from "react";
import '../../../styles/pages/Vulnerabilities2/Overview/OverviewTable.scss';
import {tabConfig} from "../config/overviewconfig";

const VulnerabilityTrendsGraph = ({data}) => {
  const [currTab, setCurrTab] = useState('Arrival rate')
  const [mappedGraphData, setMappedGraphData] = useState([]);
  const [chartWidth, setChartWidth] = useState(800); // Initial width
  const containerRef = useRef(null);
  const rootStyles = getComputedStyle(document.documentElement);
  const fontColor = rootStyles.getPropertyValue('--font-color').trim();

  useEffect(()=>{
    setMappedGraphData(mapVulnerabilitiesChartData(data))
  },[data])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setChartWidth(entry.contentRect.width);
      }
    });

    const currentRef = containerRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div>
      <div className={`vuln-graph-container title`}>
        <p className={`graph-title`}>VULNERABILITY TRENDS
          <span className={"graph-subtitle"}> OVER TIME</span>
        </p>
      </div>
      <div className="newvuln-panel-tabs">
        {tabConfig.map((tab) =>
          <Tab
            onClick={() => setCurrTab(tab.display)}
            key={tab.configName}
            data={tab}
            selected={currTab === tab.display}
          />
        )}
        <div className="bar-chart-tooltip">
          <Tooltip
            content={tooltipMap[currTab]}
            direction="right"
            disableTimeout
          >
            <img
              className="tooltip-icon"
              src={TooltipIcon}
              alt="Instructions"
            />
          </Tooltip>
        </div>
      </div>
      <div className="bar-chart-container" ref={containerRef}>
        <BarChart
          data={mappedGraphData.find(item => item.name === currTab)?.values || []}
          width={chartWidth}
          height={200}
          useLowercase
          fontColor={fontColor}
        />
      </div>
    </div>
  )
}

export default VulnerabilityTrendsGraph;
