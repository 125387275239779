import {
  getTickets,
  createTicket,
  updateTicket,
  addTicketAttachments,
  deleteTicket,
} from "../../api/ApiClient";
import { requiredInputs } from "./config/createModalConfig";
import { captureSentryError } from "../../util/sentry";
import {mockWGSupportData} from "./config/mockdata";

export const getTicketsData = async ({
  setTicketsLoading,
  setTicketsData,
  setFetchError,
  userData,
  ticketsParams,
  searchTerm,
  yPos,
  defaultID,
  onRowClick
}) => {
  const isWatchguard = userData?.tenantConfig?.is_watchguard
  setFetchError(false);
  setTicketsLoading(true);
  try {
    if (searchTerm) {
      ticketsParams["search_term"] = searchTerm;
    } else {
      delete ticketsParams.search_term;
    }
    if (ticketsParams.q) {
      delete ticketsParams.q
    }
    let tempTicketsParams = ticketsParams
    if (defaultID) {tempTicketsParams = {}}
    let ticketsResponse = isWatchguard ? mockWGSupportData : await getTickets(userData?.tenant, tempTicketsParams);
    let tickets = []
    if (defaultID) {
      tickets = ticketsResponse.results.find(obj => obj.id === defaultID)
      if (tickets) {tickets = [tickets]} else tickets = []
      let total = tickets.length
      ticketsResponse = {results: tickets, total}
      if (tickets.length > 0) {
        onRowClick('', tickets[0], false)
      }
      setTicketsData({results: tickets, total: tickets.length});
    } else {
      setTicketsData(ticketsResponse);
    }
    document.getElementsByTagName("body")[0]?.scrollTo(0, yPos);
  } catch (e) {
    captureSentryError(e, userData, "getTicketsResponse API in SupportTicket/util.js");
    setFetchError(true);
  } finally {
    setTicketsLoading(false);
  }
};

export const handleCreate = async ({
  setSubmitLoading,
  setError,
  userData,
  inputValues,
  handleClose,
  files,
  handleFetchTickets,
  setInputError
}) => {
  setSubmitLoading(true);
  setInputError("");
  try {
    let inputError;
    requiredInputs.forEach((input) => {
      if (!inputError && !inputValues[input.value]) inputError = input.error;
    });
    if (inputError) setInputError(inputError);
    else {
      const response = await createTicket(userData?.tenant, inputValues);
      if (response.errors.length) {
        setError(response.errors);
      }
      if (files.length) {
        await addTicketAttachments(
          userData?.tenant,
          response.id,
          files,
          {
            file_names: files.map((f) => f.name),
          },
          userData?.email
        );
      }
      handleClose();
      handleFetchTickets();
    }
  } catch (e) {
    setError(e.message);
    captureSentryError(e, userData, "createTicket API in SupportTicket/util.js");
  }
  setSubmitLoading(false);
};

export const handleUpdate = async ({
  setSubmitLoading,
  inputValues,
  userData,
  data,
  handleClose,
  setError,
  setTicketsData,
  files,
  setOpenTicketsData,
}) => {
  setSubmitLoading(true);
  try {
    const mappedValues = {
      subject: inputValues.subject,
      status: inputValues.status,
      description: inputValues.description,
    };
    if (files?.length) {
      await addTicketAttachments(
        userData?.tenant,
        data.id,
        files,
        {
          file_names: files.map((f) => f.name),
        },
        userData?.email
      );
    } else await updateTicket(userData?.tenant, mappedValues, data.id);

    const ticketsResponse = await getTickets(userData?.tenant);
    const updatedObject = ticketsResponse.results.find((t) => t.id === data.id);
    setTicketsData((prevData) => {
      const prevObject = prevData.results.findIndex((t) => t.id === data.id);
      prevData.results[prevObject] = updatedObject;
      return {...prevData};
    });
    setOpenTicketsData((prevOpen) =>
      Object.keys(prevOpen).length ? updatedObject : prevOpen
    );
    handleClose();
  } catch (e) {
    setError(e.message);
    captureSentryError(e, userData, "updateTicket API in SupportTicket/util.js");
  }
  setSubmitLoading(false);
};

export const handleDelete = async ({
  setDeleteLoading,
  showDeleteModal,
  userData,
  setShowDeleteModal,
  setDeleteError,
  handleFetchTickets,
}) => {
  setDeleteLoading(true);
  try {
    const ticketId = showDeleteModal;
    await deleteTicket(userData?.tenant, ticketId);
    setDeleteLoading(false);
    setShowDeleteModal(false);
    handleFetchTickets();
  } catch (e) {
    setDeleteError(true);
    captureSentryError(e, userData, "deleteTicket API in SupportTicket/util.js");
  }
};
