import { formatTime } from "../../util/format";
import { disclaimer } from "./config";

export const processTimelineData = (timeline) => {
  return timeline?.map((item) => {
    const timestampMatch = item.match(/timestamp='([^']+)'/);
    const affectedAssetMatch = item.match(/affected_asset='([^']+)'/);
    const occurrenceMatch = item.match(/occurrence='([^']+)'/);

    const timestamp = timestampMatch ? timestampMatch[1] : "";
    const affectedAsset = affectedAssetMatch ? affectedAssetMatch[1] : "";
    const occurrence = occurrenceMatch ? occurrenceMatch[1] : "";

    return {
      timestamp: formatTime(timestamp),
      affectedAsset,
      occurrence,
    };
  });
};

export const validateInput = ({
  selectedTenant,
  selectedModules,
  searchValue,
  setError,
}) => {
  if (!selectedTenant) {
    setError("Tenant must be selected.");
    return false;
  }
  if (selectedModules.length === 0) {
    setError("At least one module must be selected.");
    return false;
  }
  if (!searchValue) {
    setError("Must enter a start point value.");
    return false;
  }
  return true;
};

export const handleDetectionSearch = async ({
  tenant,
  id,
  setResultsLoading,
  setResults,
  getAIEDetectionInvestigation,
  setTraceID,
  captureSentryError,
  userData,
  setError,
}) => {
  try {
    setResultsLoading(true);
    setResults({
      llm_summary: {},
      modules: [],
    });
    const results = await getAIEDetectionInvestigation(tenant, id);
    setResults({
      llm_summary: {
        summary: {
          text: results?.summarized_logs,
          module: "summarized_logs",
        },
        findings: { text: results?.findings, module: "findings" },
        timeline: {
          text: results?.summarized_timeline,
          module: "summarized_timeline",
        },
        traceId: results?.trace_id,
      },
    });
    setTraceID(results?.trace_id);
  } catch (e) {
    captureSentryError(
      e,
      userData,
      "GET AIE detections in AdhocInvestigation.js"
    );
    setError(e.message);
  } finally {
    setResultsLoading(false);
  }
};

export const fetchInvestigationData = async ({
  selectedTenant,
  selectedStartPoint,
  searchValue,
  selectedModules,
  initiateAIEResponse,
  setError,
  captureSentryError,
  userData,
}) => {
  try {
    setError(false);
    const response = await initiateAIEResponse(selectedTenant.value, {
      [selectedStartPoint.inputValue]: searchValue.trim(),
      modules: selectedModules.map((mod) => mod.value),
      agent_type: selectedStartPoint.agent,
    });
    return response.session_id;
  } catch (e) {
    setError(e.message);
    captureSentryError(
      e,
      userData,
      "error in fetchInvestigationData API in AdhocInvestigation.js"
    );
  }
};

export const handlePollResults = async ({
  response,
  setTraceID,
  setResults,
  setError,
  setResultsLoading,
  captureSentryError,
  userData,
}) => {
  try {
    const findingsUrl = response.files.find(
      (file) => file.file_name === "findings.json"
    )?.url;
    const summaryUrl = response.files.find(
      (file) => file.file_name === "summary.json"
    )?.url;
    const traceIdUrl = response.files.find(
      (file) => file.file_name === "trace_id.json"
    )?.url;
    const timelineUrl = response.files.find(
      (file) => file.file_name === "timeline.json"
    )?.url;

    const results = {};
    if (findingsUrl && summaryUrl) {
      const [findingsResponse, summaryResponse, traceIdResponse] =
        await Promise.all([
          fetch(findingsUrl),
          fetch(summaryUrl),
          fetch(traceIdUrl),
        ]);

      const findingsData = await findingsResponse.json();
      const summaryData = await summaryResponse.json();
      const traceID = await traceIdResponse.json();

      const formattedFindings = Object.entries(findingsData.findings).map(
        ([module, text]) => ({
          module,
          text,
        })
      );

      const formattedSummary = {
        summary: summaryData?.summary,
      };

      setTraceID(traceID?.trace_id);

      results.llm_summary = formattedSummary;
      results.modules = formattedFindings.filter(
        (f) => f.module !== "trace_id"
      );
    }

    if (timelineUrl) {
      const timelineResponse = await fetch(timelineUrl);
      const timelineJSON = await timelineResponse?.json();
      const processedTimeline = processTimelineData(timelineJSON.timeline);
      const formatted = (
        <div>
          <div className="disclaimer">{disclaimer}</div>
          <ul>
            {processedTimeline?.map((item, index) => (
              <li
                key={index}
              >{`${item.timestamp} — ${item.affectedAsset} — ${item.occurrence}`}</li>
            ))}
          </ul>
        </div>
      );
      results.timeline = formatted;
    }

    setResults(results);
  } catch (e) {
    captureSentryError(e, userData, "handlePollResults function");
    setError(e.message);
  } finally {
    setResultsLoading(false);
  }
};
