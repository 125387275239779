import React, {useContext, useEffect, useState} from "react";
import ConnectionsHeartBackground from "./config/images/Connections-heart.svg";
import {getConnectionsPanelData} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import '../../styles/pages/Dashboard/ConnectionsPanel.scss';
import * as d3 from "d3";
import {Loader} from "../../components/Loader/Loader";
import {useNavigate} from "react-router-dom";
import {abbreviateNumber} from "../../util/format";
import Tooltip from "../../components/Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import { captureSentryError } from "../../util/sentry";

const ConnectionsPanel = ({setFetchError}) => {
  const [userData] = useContext(UserData);
  const navigate = useNavigate();
  const [connectionsPanelLoading, setConnectionsPanelLoading] = useState(false)
  const [connectionsData, setConnectionsData] = useState({})
  const isMDRLite = userData?.tenantConfig?.is_mdr_lite;
  const isMobileOnly = userData?.tenantConfig?.is_mobile_edr_only;
  const isWatchguard = userData?.tenantConfig?.is_watchguard

  const onClick = () => {
    navigate('/connections/service-status')
  }

  useEffect(() => {
    let AVAILABLE_CONNECTORS = isWatchguard ? ['cloud', 'endpoints', 'firewall', 'identity'] :
      ['cloud', 'endpoints', 'firewall', 'mobile', 'identity'];
    const positionElements = (name, data) => {
      let svg = d3.select('.connections-svg')
      Array.from(document.getElementsByClassName(`${name}-text connections-text`)).forEach(
        (element) => {
          element.remove()
        }
      );

      const mapValues = {
        endpoints: {left: "28.5", top: "80", right: "210px"},
        mobile: {left: "20.5", top: "117.5", right: '220px'},
        firewall: {left: "206", top: "117.5", right: '-40px', width: "30px"},
        identity: {left: "190", top: "151", right: '-40px', width: "48px"},
        cloud: {left: "198.5", top: "80", r: 8}
      }

      let circleColor = (data?.status !== undefined && data?.status !== false) ?
        data.status : isMDRLite ? 'grey' : 'transparent'

      if (isMobileOnly && name !== 'mobile') circleColor = '#5D5C5C'

      const showGrey = (name) => {
        if (isMDRLite && (name === 'firewall' || name === 'identity')) {
          return true
        } else if (isMobileOnly && name !== 'mobile') {
          return true
        }
      }

      d3.selectAll(`.${name}-circle`).remove()
      if (data.count !== 0 || showGrey(name)) {
        svg.append("circle")
          .attr("class", `${name}-circle`)
          .style("fill", circleColor)
          .attr("r", 8)
          .style("cx", mapValues[name].left)
          .style("cy", mapValues[name].top)
      }


      const rootStyles = getComputedStyle(document.documentElement);
      const getColor = (colorVar) => rootStyles.getPropertyValue(colorVar).trim();

      data.status = {
        green: getColor('--green'),
        red: getColor('--red'),
        yellow: getColor('--yellow')
      }[data.status] || data.status;

      let isRed = data.status === getColor('--red') && data.count !== 0;
      let statusColor = isRed ? getColor('--red') : getColor('--font-color');

      const setStatusColor = () => {
        if (isMDRLite && (name === 'firewall' || name === 'identity')) {
          statusColor = 'grey'
        } else if (isMobileOnly && name !== 'mobile') {
          statusColor = 'grey'
        }
      }
      setStatusColor()

      let div = d3.select('.dashboard-connections-graph')
      let total = div.append("text")

      total
        .attr("class", `${name}-text connections-text` +
          (isWatchguard && (name === 'firewall' || name === 'identity') ? ' low-opacity' : ''))
        .style("right", mapValues[name].right)
        .style("top", JSON.stringify(mapValues[name].top - 10) + "px")
        .style('fill', statusColor)
        .style('color', statusColor)
        .style('width', mapValues[name]?.width ? mapValues[name]?.width : 'auto')
        .html(`${name.charAt(0).toUpperCase() + name.slice(1)} <i>${abbreviateNumber(data.count)}</i>`);
    }

    Object.entries(connectionsData).length > 0 && Object.entries(connectionsData).forEach(([name, data]) => {
      AVAILABLE_CONNECTORS.includes(name) && positionElements(name, data)
    })
  }, [connectionsPanelLoading, connectionsData, isMDRLite, isMobileOnly, isWatchguard])

  useEffect(() => {
    const getConnectionsPanel = async () => {
      setFetchError(false);
      setConnectionsPanelLoading(true)
      try {
        let connectionsPanelResponse = await getConnectionsPanelData(userData?.tenant)
        setConnectionsData(connectionsPanelResponse)
      } catch (e) {
        captureSentryError(e, userData, "getConnectionsPanelData API in ConnectionsPanel.js");
        setFetchError(true);
      } finally {
        setConnectionsPanelLoading(false)
      }
    }
    userData?.tenant && getConnectionsPanel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType]);

  return (
    <div className={"connections-panel"}>
      {connectionsPanelLoading ? <div className={"loader-container"}><Loader/></div> :
        <>
          <Tooltip
            content={tooltipMapping.dashboard.connections}
            className={"connections-panel-tooltip"}
            direction={"right"}
          >
            <p className={"circle-graph-title connections-title"}><b>CONNECTIONS</b></p>
          </Tooltip>
          <div
            onClick={() => onClick()}
            className={"dashboard-connections-graph"}
            style={{backgroundImage: `url(${ConnectionsHeartBackground}`}}
          >
            <svg className={"connections-svg"}/>
          </div>
        </>
      }
    </div>
  );
};

export default ConnectionsPanel;
