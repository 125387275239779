import React from "react";
import "../../../styles/pages/AdhocInvestigation/DetectionPanels.scss";
import { processTimelineData } from "../util";
import { Loader } from "../../../components/Loader/Loader";

const DetectionPanels = ({ data, loading, className, setCommentModalData }) => {

  const renderSection = (header, className, sectionData) => (
    <div className={`section ${className}-section`}>
      <div className={`header ${className}`}>{header}</div>
      {!loading ? (
        <ul>
          {sectionData?.text?.map((val, index) => (
            <li key={index}>{val}</li>
          ))}
        </ul>
      ) : (
        <Loader />
      )}
    </div>
  )

  return (
    <div className={`detection-panel-container ${className}`}>
      {data.summary &&
      renderSection("Summary", "summary", {
        ...data.summary,
        text: [data.summary.text],
      })}
      <div className="detection-right-side">
        {data?.timeline &&
        renderSection("Timeline", "timeline", {
          ...data.timeline,
          text: processTimelineData(data.timeline.text)?.map(
            (item) =>
              `${item.timestamp} — ${item.affectedAsset} — ${item.occurrence}`
          ),
        })}
        {data.affected_assets &&
        renderSection(
          "Affected Assets",
          "affected_assets",
          data.affected_assets
        )}
        {data.findings && renderSection("Findings", "findings", data.findings)}
      </div>
    </div>
  );
};

export default DetectionPanels;
