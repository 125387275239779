export const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

const tenantDropdownStyles = {
  option: (provided, state) => ({
    ...provided,
    color: (state.isSelected || state.isFocused) ? getCSSVariable('--blue')
      : getCSSVariable('--font-color'),
    padding: 10,
    cursor: 'pointer',
    backgroundColor: state?.data?.is_onboarding ? getCSSVariable('--grey') :
      getCSSVariable('--secondary-bg'),
    border: state.isFocused ? `1px solid ${getCSSVariable('--darkgrey')}`
      : `1px solid ${getCSSVariable('--grey')}`,
  }),
  control: () => ({
    marginTop: '5px',
    width: 200,
    color: getCSSVariable('--yellow'),
    display: 'flex',
    border: `1px solid ${getCSSVariable('--font-color')}`,
    cursor: 'pointer'
  }),
  singleValue: (provided) => {
    const color = getCSSVariable('--blue');
    return {...provided, color};
  },
  input: (provided) => {
    const color = getCSSVariable('--font-color');
    return {...provided, color};
  },
  menuList: base => ({
    ...base,
    padding: 0,
    marginTop: '-8px',
    overflowX: 'hidden',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  })
}

export default tenantDropdownStyles
