import React, { useState, useEffect } from "react";
import "../../../styles/pages/AdhocInvestigation/ResultsPanel.scss";
import { Loader } from "../../../components/Loader/Loader";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactTyped } from "react-typed";
import FeedbackButtons from "./FeedbackButtons";

const ResultsPanel = ({ data, setCommentModalData, traceID }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [typedOnce, setTypedOnce] = useState(false);
  const loading = !data.text;

  useEffect(() => setTypedOnce(false), [data.text]);

  return (
    <div className="results-panel">
      <div className="header">
        <div>{data?.module.replace(/_/g, " ")}</div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="actions">
            <FeedbackButtons
              traceID={traceID}
              sectionData={data}
              setCommentModalData={setCommentModalData}
              agent="adhoc"
            />
          </div>
          <div
            onClick={() => setCollapsed(!collapsed)}
            className="collapse-button"
          >
            {collapsed ? (
              <ExpandMoreIcon
                fontSize="inherit"
                className="panel-icon collapse"
              />
            ) : (
              <ExpandLessIcon
                fontSize="inherit"
                className="panel-icon collapse"
              />
            )}
          </div>
          {!collapsed && (
            <div className="text-box">
              {!typedOnce && (
                <ReactTyped
                  strings={[data?.text]}
                  typeSpeed={20}
                  loop={false}
                  showCursor={false}
                  onComplete={() => setTypedOnce(true)}
                  onDestroy={() => setTypedOnce(true)}
                />
              )}
              {typedOnce && <div>{data?.text}</div>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResultsPanel;
