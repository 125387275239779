import React, {useCallback, useContext, useEffect, useState} from "react";
import ControlsGearBackground from "./config/images/Controls-gear.svg";
import {getOverallMAData} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import '../../styles/pages/Dashboard/ControlsPanel.scss';
import * as d3 from "d3";
import {Loader} from "../../components/Loader/Loader";
import CircularGraph from "../../components/D3/CircularGraph/CircularGraph";
import {formatLargeNumber} from "../../util/format";
import Tooltip from "../../components/Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import {useNavigate} from "react-router-dom";
import {captureSentryError} from "../../util/sentry";
import {mockWGControlsPanel} from "./config/mockdata";

const ControlsPanel = ({setFetchError}) => {
  const [userData] = useContext(UserData);
  const [controlsPanelLoading, setControlsPanelLoading] = useState(false)
  const [controlsTotal, setControlsTotal] = useState({})
  const [controlsData, setControlsData] = useState([])
  const isMDRLite = userData?.tenantConfig?.is_mdr_lite;
  const isMobileOnly = userData?.tenantConfig?.is_mobile_edr_only
  const navigate = useNavigate();
  const rootStyles = getComputedStyle(document.documentElement);
  const green = rootStyles.getPropertyValue('--green').trim();
  const yellow = rootStyles.getPropertyValue('--dark-yellow').trim();
  const orange = rootStyles.getPropertyValue('--orange').trim();
  const red = rootStyles.getPropertyValue('--red').trim();
  const blue = rootStyles.getPropertyValue('--blue').trim();
  const grey = rootStyles.getPropertyValue('--grey').trim();
  const isWatchguard = userData?.tenantConfig?.is_watchguard

  const onClick = useCallback((state) => {
    navigate('/risk-management/maturity-assessment', {
      state: {
        toolCategoryParams: state
      }
    })

  }, [navigate])

  useEffect(() => {
    const addTotalCount = (tool, count) => {
      const mapValues = {
        "Endpoint Protection Platform": {left: "208px", top: "11px", color: red, name: 'Endpoint'},
        "Firewall": {left: "-10px", top: "65px", color: orange, name: 'Firewall'},
        "Vulnerability Scanning": {left: "-35px", top: "109px", color: yellow, name: 'Vulnerability'},
        "Managed Detection and Response": {left: "5px", top: "160px", color: blue, name: 'MDR'},
      }

      let value = count > 999 ? formatLargeNumber(count) : count

      let state = {
        order: "asc",
        page: 1,
        size: 50,
        tool: tool,
        sort_by: "tool",
        status: ['Completed']
      }

      let div = d3.select('.dashboard-controls-graph')
      let total = div.append("text")
        .attr("text-anchor", "middle")
        .attr("class", `${tool.toLowerCase()} total-count`)
        .attr("dy", "0.3em")
        .style("left", mapValues[tool].left)
        .style("top", mapValues[tool].top)
        .style("color", 'grey')
        .style('cursor', (!isMDRLite && !isMobileOnly) && 'pointer')
        .style("color", isWatchguard ? grey : mapValues[tool].color)
        .on("click", () => {
          onClick(state)
        });

      if ((!isMDRLite && !isMobileOnly)) {
        if (tool === "Endpoint Protection Platform") {
          total.text(value?.toString() + '\xa0\xa0\xa0\xa0\xa0' + mapValues[tool].name);
        } else {
          total.text(mapValues[tool].name + '\xa0\xa0\xa0\xa0\xa0' + value?.toString());
        }
      }

      let tooltip = total
        .append("div")
        .attr("class", `d3-tooltip ${tool}`)
        .style("opacity", 0);

      let timeout;
      (!isMobileOnly && !isMDRLite) && total.append("div").attr("class", `d3-tooltip-mouseover`).on("mouseover", () => {
        timeout = setTimeout(() => tooltip
          .style("opacity", (isMDRLite || isMobileOnly) ? 0 : 1)
          .text(`Number of ${tool.toLowerCase()} controls`), 500)
      })
        .style('cursor', (!isMDRLite && !isMobileOnly) && 'pointer')
        .on("mouseout", () => {
          clearTimeout(timeout);
          tooltip.style("opacity", 0);
        })
    }

    controlsData.length > 0 &&
    controlsData?.forEach(item => {
      addTotalCount(item.tool, item.completed)
    })
    // eslint-disable-next-line
  }, [controlsPanelLoading, controlsData, isMDRLite, isMobileOnly, onClick])

  useEffect(() => {
    const getControlsPanel = async () => {
      if (userData.tenantConfig === undefined) {
        return;
      }
      setFetchError(false);
      setControlsPanelLoading(true)
      try {
        let params = {
          group_by: 'tool',
          tool: ["Firewall", "Endpoint Protection Platform", "Vulnerability Scanning", "Managed Detection and Response"]
        }
        let controlsPanelResponse;
        if (isWatchguard) controlsPanelResponse = mockWGControlsPanel
        else controlsPanelResponse = await getOverallMAData(userData?.tenant, params)
        let percentCompleted = controlsPanelResponse?.overall?.percent_completed
        let data = {name: 'controls', value: percentCompleted / 100, stringValue: percentCompleted + '%'}
        setControlsData(controlsPanelResponse?.tools)
        if (isMDRLite || isMobileOnly) {
          data = {name: 'controls', value: 0, stringValue: ''}
        }
        setControlsTotal(data)
      } catch (e) {
        captureSentryError(e, userData, 'getOverallMAData API in ControlsPanel.js');
        setFetchError(true);
      } finally {
        setControlsPanelLoading(false)
      }
    }
    userData?.tenant && getControlsPanel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType, isMDRLite, isMobileOnly]);

  const calculateColor = (value) => {
    if (isMobileOnly && isMDRLite) return blue
    return value <= .25 ? red : value > .25 && value <= .50 ? orange : value > .50 && value <= .75 ? yellow : green
  }

  return (
    <div className={"controls-panel" + (isWatchguard ? ' disabled-grey' : '')}>
      {isWatchguard && <p className={"coming-soon-text"}>COMING SOON</p>}
      {controlsPanelLoading && <div className={"loader-container"}><Loader/></div>}
      {controlsTotal.hasOwnProperty("name") && !controlsPanelLoading &&
      <>
        <Tooltip
          content={tooltipMapping.dashboard.controls}
          className={"controls-panel-tooltip"}
          direction={"right"}
        >
          <p
            className={"circle-graph-title controls-title" + ((isMDRLite || isMobileOnly) ?
              " greyed" : '') + (isWatchguard ? ' low-opacity' : '')}
          >
            <b>CONTROLS</b></p>
        </Tooltip>
        <div
          className={"dashboard-controls-graph" + ((isMDRLite || isMobileOnly) ? " greyed" : '')
          + (isWatchguard ? ' disable-click disabled-grey low-opacity' : '')}
          style={{backgroundImage: `url(${ControlsGearBackground}`}}
        >
          <CircularGraph
            onClick={() => onClick({
              order: "asc",
              page: 1,
              size: 50,
              sort_by: "tool",
              status: ['Completed']
            })}
            containerName={'dashboard-controls'}
            data={controlsTotal}
            color={(isMDRLite || isMobileOnly) ? "grey" : calculateColor(controlsTotal.value)}
            xAxis={"0"}
          />
        </div>
      </>
      }
    </div>
  );
};

export default ControlsPanel;
