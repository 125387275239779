import config from '../../config'
import { exchangeCognitoCodeForTokens } from "../../api/ApiClient";

const redirectUri = `https://defense-${config.env.replace("_", ".")}.actzero.ai/sso`;  // const redirectUri = "http://localhost:3000/sso";

async function getWatchGuardCognitoTokens(code) {
    let body = {code: code}
    if (["dev", "staging", "staging_eu"].includes(config.env)) {
        body.redirect_uri = redirectUri
    }
    return await exchangeCognitoCodeForTokens(body);
}

export default getWatchGuardCognitoTokens;
