import {signOut} from "aws-amplify/auth";
import { Cache } from 'aws-amplify/utils';
import config from '../config';

export const handleSignOut = (isWatchGuard) => {
    const tenant = localStorage.getItem("tenant");
    localStorage.clear();
    Cache.clear();
    signOut();
    localStorage.setItem('tenant', tenant);
    if (isWatchGuard) {
      window.location.replace(config.wg_sso_logout_redirect_destination);
    } else {
      window.location.href = "/";
    }
  };

// Handle case where signOut throws error if user no longer exists
export const handleResetAccountSignOut = (isWatchGuard) => {
  try {
    handleSignOut(isWatchGuard);
  } catch (e) {
    window.location.href = "/";
    window.location.reload();
  }
};
