import React, {useContext, useEffect, useState} from "react";
import {getTrends, getVulnerabilityMetrics} from "../../api/ApiClient";
import {captureSentryError} from "../../util/sentry";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {mapChartData} from "../../util/mapChartData"
import '../../styles/pages/MultiTenantDashboard/VulnerabilityTrends.scss';
import {vulnGraphValues, tooltipMap} from "./config/graph-config";
import BetaIcon from "../../assets/icons/beta2.svg";
import {onTenantClick} from "./config/helper";
import BarChart from "../../components/D3/BarChart/BarChart";
import Tab from "../../components/Tab/Tab";
import TooltipIcon from "../../assets/icons/tooltip.svg";
import Tooltip from "../../components/Tooltip/Tooltip";

const VulnerabilityTrends = () => {
  const [mappedGraphData, setMappedGraphData] = useState([])
  const [userData] = useContext(UserData);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [tabConfig, setTabConfig] = useState([]);
  const [vulnerabilitiesList, setVulnerabilitiesList] = useState([]);
  const [currTab, setCurrTab] = useState("");
  const rootStyles = getComputedStyle(document.documentElement);
  const fontColor = rootStyles.getPropertyValue('--font-color').trim();

  useEffect(() => {
    const getMetricsData = async () => {
      setLoading(true)
      try {
        const data = await getVulnerabilityMetrics(userData?.tenant)
        let results = mapChartData(data?.vulnerability_trends, ()=>{}, setMappedGraphData, vulnGraphValues, [])
        const sortedTabs = results.map(a => ({display: a.name, configName: a.name.replace(' ', '-').toLowerCase()})).sort((a, b) => a.display.localeCompare(b.display));
        setTabConfig(sortedTabs);
        if (results.length > 0) setCurrTab(sortedTabs[0].display);
      } catch (e) {
        captureSentryError(e, userData, 'getVulnerabilityMetrics')
      } finally {
        setLoading(false)
      }
    }

    const getTrendData = async () => {
      setLoadingTable(true)
      try {
        const data = await getTrends(userData?.tenant, 'vulnerabilities')
        setVulnerabilitiesList(data)
      } catch (e) {
        captureSentryError(e, userData, 'getTrends vulnerability trends')
      } finally {
        setLoadingTable(false)
      }
    }

    userData?.tenant && getTrendData() && getMetricsData()
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType])

  return (
    <div className={"multi-tenant-panel vulnerabilities"}>
      {loading ? <Loader/> :
        mappedGraphData.length > 0 && mappedGraphData[2].values?.length > 0 ?
          <div className={"graph-container vulnerabilities-panel"}>
            <div className={`title-and-legend title`}>
              <p className={`graph-title`}>VULNERABILITY TRENDS
                <span className={"sub-title"}> OVER TIME</span>
                <span><img className={"beta-icon"} src={BetaIcon} alt={"beta"}/></span>
              </p>
            </div>
            <div className="vuln-panel-tabs">
              {tabConfig.map((tab) =>
                <Tab
                  onClick={() => setCurrTab(tab.display)}
                  key={tab.configName}
                  data={tab}
                  selected={currTab === tab.display}
                />
              )}
              <div className="bar-chart-tooltip">
                <Tooltip
                  content={tooltipMap[currTab]}
                  direction="right"
                  disableTimeout
                >
                  <img
                    className="tooltip-icon"
                    src={TooltipIcon}
                    alt="Instructions"
                  />
                </Tooltip>
              </div>
            </div>
            <div className="bar-chart-container">
              <BarChart
                data={mappedGraphData.filter(item => item.name === currTab).map(obj => obj.values)[0]}
                width={850}
                height={200}
                fontColor={fontColor}
              />
            </div>
          </div> : <></>}
      {loadingTable ? <Loader/> :
        <div className={"flex vulnerabilities-tables"}>
          <div className={"panel-tables"}>
            <p className={"title"}>MOST VULNERABLE TENANTS</p>
            {vulnerabilitiesList?.most_vulnerable_tenants?.map(item =>
              <p className={"detection-value"} key={item.friendly_name}>
                <span className={"label"} onClick={() => onTenantClick(item.tenant_id, '/risk-management/vulnerabilities')}>
                  {item.friendly_name}
                </span>
              </p>
            )}
          </div>
          <div className={"panel-tables"}>
            <p className={"title"}>TOP VULNERABILITIES</p>
            {Object.entries(vulnerabilitiesList?.top_vulnerabilities).map(
              (item) => (
                <p className={"detection-value"} key={item[0]}>
                  {item[0]?.startsWith("CVE") ? (
                    <a href={`https://nvd.nist.gov/vuln/detail/${item[0]}`} target="_blank" rel="noreferrer">
                      <span className={"label"}>{item[0]}</span>
                    </a>
                  ) : (
                    <span className={"label inactive"}>{item[0]}</span>
                  )}
                </p>
              )
            )}
          </div>
        </div>}
    </div>
  )
}

export default VulnerabilityTrends;
