export const lineData = {
  lineOpacity: "1",
  lineOpacityHover: "1",
  otherLinesOpacityHover: "0.3",
  lineStroke: "3px",
  lineStrokeHover: "5px",
};

const rootStyles = getComputedStyle(document.documentElement);
const blue = rootStyles.getPropertyValue('--blue').trim();
const green = rootStyles.getPropertyValue('--green').trim();
const red = rootStyles.getPropertyValue('--red').trim();
const orange = rootStyles.getPropertyValue('--orange').trim();

export const colors = {
  Inbound: green,
  "Inbound blocked": red,
  Outbound: blue,
  "Outbound blocked": orange,
};

export const defaultChartLines = [
  { name: "Outbound", values: [] },
  { name: "Outbound blocked", values: [] },
  { name: "Inbound", values: [] },
  { name: "Inbound blocked", values: [] },
];

export const filterMapping = {
  allow: ["Outbound", "Inbound"],
  deny: ["Outbound blocked", "Inbound blocked"],
  inbound: ["Inbound", "Inbound blocked"],
  outbound: ["Outbound", "Outbound blocked"],
  default: ['Outbound', 'Inbound', 'Outbound blocked', 'Inbound blocked']
};

export const countryTableConfig = [
  { header: "", value: "name" },
  { header: "INCOMING", value: "inbound" },
  { header: "OUTGOING", value: "outbound" },
  { header: "TOTAL", value: "total" },
];

export const trendsTableConfig = {
  month_network_traffic_change: {
    text: "Change in total connections over last 30 days",
  },
};

export const trafficMenuOptions = {
  "All traffic": null,
  Allowed: "allow",
  Blocked: "deny",
};

export const sourceMenuOptions = {
  "All sources": null,
  Inbound: "inbound",
  Outbound: "outbound",
};

export const dropdownConfig = [
  {
    name: "Source",
    className: "source",
    options: sourceMenuOptions,
    value: "source",
  },
  {
    name: "Traffic",
    className: "action",
    options: trafficMenuOptions,
    value: "action",
  }
];

export const mapRenderConfig = [{ type: "incoming" }, { type: "outgoing" }];

export const defaultMapPosition = {
  zoom: 1,
  coordinates: [5, -30],
};

export const maxZoom = 8;
export const minZoom = 1;

export const networkPageTooltip =
  "Provides an interactive view of your network traffic, exclusively based on IP geolocation enrichment derived from source and destination IPs extracted from your firewall logs. Hover over any country to display statistics on total incoming, outgoing, and blocked connections. It serves as a tool for identifying traffic trends, encouraging you to proactively adjust your firewall for optimal network and security management.";
