import React from "react";
import "../../../../styles/pages/Onboarding/VulnerabilityScan/TableBody.scss";
import {confirmDialogs, rowButtonConfig} from "../config";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import {IconButton} from "@mui/material";
import ConfirmDialogCell from "../../../../components/Table/ConfirmDialogCell";

export const TableBody = ({
                            data,
                            setModalData,
                            tableHeaders,
                            setPendingRow,
                            pendingRow,
                            handleSubmit,
                            resetPendingStages,
                            rowError,
                            rowLoading,
                            isRootUser
                          }) =>
  data?.map((row) => (
    <tr key={row.id}>
      {Object.keys(tableHeaders).map((col) =>
        col === "scan_ips" ? (
          <td
            onClick={() => setModalData(row)}
            className={`cell-content clickable ${col}`}
            key={col}
          >
            <div className="truncate">
              {row[col].map(
                (ip, i) => `${ip}${i < row[col].length - 1 ? ", " : ""}`
              )}
            </div>
            <span className="arrow-link">{" »"}</span>
          </td>
        ) : (
          <td className={`cell-content ${col}`} key={col}>
            {row[col]}
          </td>
        )
      )}
      {pendingRow?.row?.id === row.id && pendingRow.action !== "edit" ? (
        <ConfirmDialogCell
          message={confirmDialogs[pendingRow.action]}
          confirmFunc={handleSubmit}
          cancelFunc={resetPendingStages}
          rowError={rowError}
          rowLoading={rowLoading}
        />
      ) : (
        <td className={"cell-content icon-btns"}>
          {rowButtonConfig.map((b, i) => (
            <Tooltip
              content={b.tooltip}
              className={`${b.action}-tooltip`}
              direction={"right"}
              key={i}
            >
              <IconButton
                className={`table-icon ${b.action}`}
                onClick={() => setPendingRow({row: row, action: b.action})}
              >
                <img src={b.icon} alt={b.tooltip} className="icon-img"/>
              </IconButton>
            </Tooltip>
          ))}
        </td>
      )}
    </tr>
  ));
