import React from "react";
import "../../../styles/pages/AdhocInvestigation/SummaryPanel.scss";
import { Loader } from "../../../components/Loader/Loader";

const SummaryPanel = ({ data, loading, heading, className }) => {
  return (
    <div className={`summary-panel-container ${className}`}>
      <div className={`header summary`}>{heading}</div>
      {!loading ? <div className="summary-text">{data}</div> : <Loader />}
    </div>
  );
};

export default SummaryPanel;