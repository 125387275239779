export const mockCloudData = {
  "clouds": {
    "o365": {
      "status": "green",
      "last_seen": 1741597200,
      "event_count": 4647,
      "investigation_count": 2,
      "active_connections": [
        "c10fd04a-3542-41eb-8d7d-03221866f54d"
      ]
    },
    "aws": {
      "status": 'yellow',
      "last_seen": null,
      "event_count": 10,
      "investigation_count": 30,
      "active_connections": []
    },
    "gsuite": {
      "status": 'green',
      "last_seen": 1741587200,
      "event_count": 10,
      "investigation_count": 30,
      "active_connections": []
    },
    "salesforce": {
      "status": "red",
      "last_seen": null,
      "event_count": 20,
      "investigation_count": 30,
      "active_connections": []
    }
  },
  "overall": {
    "status": "yellow",
    "last_seen": 1741597200,
    "investigation_count": 2,
    "total_active_connections": 1
  }
}
