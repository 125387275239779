export const mockWGSupportData = {
  "results": [
    {
      "id": "1234asdf",
      "case_number": "000949",
      "subject": "Incident #12,889 status has been changed to New",
      "description": "Confidentiality Notice: This email and any files transmitted with it are confidential and intended solely for the use of individual(s) or entity to which it is sent even if addressed incorrectly. If you have received this e-mail in error please notify the sender by e-mail at the address shown, delete and destroy this message and its attachments. Aviso De Confidencialidad: Este correo electrónico y los archivos transmitidos con él son confidenciales y con la exclusiva intención de uso por el individuo o entidad a quien fueron enviados, inclusive si fueron dirigidos incorrectamente. Si usted ha recibido este correo electrónico por equivocación, por favor notifique inmediatamente al remitente, por medio de un correo electrónico a la dirección en el mismo mostrada y borre y destruye este mensaje y sus anexos.\n\nDisclaimer\n\nThe information contained in this communication from the sender is confidential. It is intended solely for use by the recipient and others authorized to receive it. If you are not the recipient, you are hereby notified that any disclosure, copying, distribution or taking action in relation of the contents of this information is strictly prohibited and may be unlawful.\n\nThis email has been scanned for viruses and malware, and may have been automatically archived by Mimecast, a leader in email security and cyber resilience. Mimecast integrates email defenses with brand protection, security awareness training, web security, compliance and other essential capabilities. Mimecast helps protect large and small organizations from malicious activity, human error and technology failure; and to lead the movement toward building a more resilient world. To find out more, visit our website.",
      "priority": "High",
      "created_date": "2025-03-05T15:56:40.000+0000",
      "tenant": "watchguard",
      "status": "Closed",
      "first_name": null,
      "last_name": null,
      "email": "test@email.com",
      "assigned_to": "Dan Nolan"
    },
    {
      "id": "1234dfgh",
      "case_number": "000324",
      "subject": "Automatic reply: Critical Security Updates for VMware ESXi",
      "description": "27/02/2025 > 11/03/2025\n\nKia Ora,\n\nI'm currently travelling and limited access to email, I will be delayed in returning your call and/or email.\n\nThank you for your patience\n\nNga mihi and stay safe.\n\nKurt Brandon\n\nIf your request is urgent please send a text or WhatsApp message to\n+6421333002.\n\nIf you have an urgent support call, please raise a case with customer care via the partner portal at www.watchguard.com<http://www.watchguard.com>\n\nKurt Brandon WatchGuard - New Zealand & Pacific Islands +64 21 333 002",
      "priority": "Medium",
      "created_date": "2025-03-04T23:52:41.000+0000",
      "tenant": "watchguard",
      "status": "Waiting_On_ActZero",
      "first_name": "Kurt",
      "last_name": "Brandon",
      "email": "test@email.com",
      "assigned_to": "Support Unassigned"
    },
    {
      "id": "345612sdfg",
      "case_number": "000654",
      "subject": "Incident #12,868 has been changed to Closed",
      "description": "Confidentiality Notice: This email and any files transmitted with it are confidential and intended solely for the use of individual(s) or entity to which it is sent even if addressed incorrectly. If you have received this e-mail in error please notify the sender by e-mail at the address shown, delete and destroy this message and its attachments. Aviso De Confidencialidad: Este correo electrónico y los archivos transmitidos con él son confidenciales y con la exclusiva intención de uso por el individuo o entidad a quien fueron enviados, inclusive si fueron dirigidos incorrectamente. Si usted ha recibido este correo electrónico por equivocación, por favor notifique inmediatamente al remitente, por medio de un correo electrónico a la dirección en el mismo mostrada y borre y destruye este mensaje y sus anexos.\n\nDisclaimer\n\nThe information contained in this communication from the sender is confidential. It is intended solely for use by the recipient and others authorized to receive it. If you are not the recipient, you are hereby notified that any disclosure, copying, distribution or taking action in relation of the contents of this information is strictly prohibited and may be unlawful.\n\nThis email has been scanned for viruses and malware, and may have been automatically archived by Mimecast, a leader in email security and cyber resilience. Mimecast integrates email defenses with brand protection, security awareness training, web security, compliance and other essential capabilities. Mimecast helps protect large and small organizations from malicious activity, human error and technology failure; and to lead the movement toward building a more resilient world. To find out more, visit our website.",
      "priority": "High",
      "created_date": "2025-03-04T15:40:59.000+0000",
      "tenant": "watchguard",
      "status": "Closed",
      "first_name": null,
      "last_name": null,
      "email": "test@email.com",
      "assigned_to": "Dan Nolan"
    },
    {
      "id": "989shhd",
      "case_number": "000345",
      "subject": "Incident #12,838 has been assigned to Larissa Madrigal",
      "description": "Confidentiality Notice: This email and any files transmitted with it are confidential and intended solely for the use of individual(s) or entity to which it is sent even if addressed incorrectly. If you have received this e-mail in error please notify the sender by e-mail at the address shown, delete and destroy this message and its attachments. Aviso De Confidencialidad: Este correo electrónico y los archivos transmitidos con él son confidenciales y con la exclusiva intención de uso por el individuo o entidad a quien fueron enviados, inclusive si fueron dirigidos incorrectamente. Si usted ha recibido este correo electrónico por equivocación, por favor notifique inmediatamente al remitente, por medio de un correo electrónico a la dirección en el mismo mostrada y borre y destruye este mensaje y sus anexos.\n\nDisclaimer\n\nThe information contained in this communication from the sender is confidential. It is intended solely for use by the recipient and others authorized to receive it. If you are not the recipient, you are hereby notified that any disclosure, copying, distribution or taking action in relation of the contents of this information is strictly prohibited and may be unlawful.\n\nThis email has been scanned for viruses and malware, and may have been automatically archived by Mimecast, a leader in email security and cyber resilience. Mimecast integrates email defenses with brand protection, security awareness training, web security, compliance and other essential capabilities. Mimecast helps protect large and small organizations from malicious activity, human error and technology failure; and to lead the movement toward building a more resilient world. To find out more, visit our website.",
      "priority": "High",
      "created_date": "2025-02-19T15:50:20.000+0000",
      "tenant": "watchguard",
      "status": "Closed",
      "first_name": null,
      "last_name": null,
      "email": "test@email.com",
      "assigned_to": "Dan Nolan"
    },
    {
      "id": "92928kkdkdj",
      "case_number": "000123",
      "subject": "Incident #12,838 has been assigned to Matthew Mansfield",
      "description": "Confidentiality Notice: This email and any files transmitted with it are confidential and intended solely for the use of individual(s) or entity to which it is sent even if addressed incorrectly. If you have received this e-mail in error please notify the sender by e-mail at the address shown, delete and destroy this message and its attachments. Aviso De Confidencialidad: Este correo electrónico y los archivos transmitidos con él son confidenciales y con la exclusiva intención de uso por el individuo o entidad a quien fueron enviados, inclusive si fueron dirigidos incorrectamente. Si usted ha recibido este correo electrónico por equivocación, por favor notifique inmediatamente al remitente, por medio de un correo electrónico a la dirección en el mismo mostrada y borre y destruye este mensaje y sus anexos.\n\nDisclaimer\n\nThe information contained in this communication from the sender is confidential. It is intended solely for use by the recipient and others authorized to receive it. If you are not the recipient, you are hereby notified that any disclosure, copying, distribution or taking action in relation of the contents of this information is strictly prohibited and may be unlawful.\n\nThis email has been scanned for viruses and malware, and may have been automatically archived by Mimecast, a leader in email security and cyber resilience. Mimecast integrates email defenses with brand protection, security awareness training, web security, compliance and other essential capabilities. Mimecast helps protect large and small organizations from malicious activity, human error and technology failure; and to lead the movement toward building a more resilient world. To find out more, visit our website.",
      "priority": "High",
      "created_date": "2025-02-19T15:46:43.000+0000",
      "tenant": "watchguard",
      "status": "Closed",
      "first_name": null,
      "last_name": null,
      "email": "test@email.com",
      "assigned_to": "Dan Nolan"
    },
    {
      "id": "10009300jgjkk",
      "case_number": "000123",
      "subject": "Error",
      "description": "[cid:image001.png@01DB7CB7.BE2692E0]\n\nRegards,\n\n[signature_614399196]\n\nShawn Roberts\n\no: (817) 527-8224  m: (817) 618-5974\n\nw: mvrgroup.net<http://www.mvrgroup.net/>  e: sroberts@mvrgroup.net<mailto:sroberts@mvrgroup.net>",
      "priority": "High",
      "created_date": "2025-02-12T01:04:33.000+0000",
      "tenant": "watchguard",
      "status": "Closed",
      "first_name": "Shawn",
      "last_name": "Roberts",
      "email": "sroberts@mvrgroup.net",
      "assigned_to": "Dan Nolan"
    }
  ],
  "total": 6
}
