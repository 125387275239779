import "../../../../styles/pages/Onboarding/Tenants/TenantDetailsModal.scss";
import React, { useContext, useEffect, useState } from "react";
import UserData from "../../../../store/User/UserData";
import "react-phone-number-input/style.css";
import { isRoot } from "../../../../util/userControl";
import { Modal } from "../../../../components";
import { tenantDetailEditInputs, valueMapping } from "./config";
import LoaderButton from "../../../../components/Button/LoaderButton";
import { Loader } from "../../../../components/Loader/Loader";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorRobot from "../../../../assets/icons/error-robot.svg";
import {
  getTenantData,
  handleUpdate,
  renderInput,
  renderLogManagementValue,
} from "./util";

const TenantDetailsModal = ({ tenantId, closeFunction, refreshData }) => {
  const [userData] = useContext(UserData);
  const [tenantData, setTenantData] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [inputConfig, setInputConfig] = useState([]);
  const isRootUser = isRoot(userData.userType);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const inputsToShow = tenantDetailEditInputs
      .filter((input) => (input.display === "root" ? isRootUser : true))
      .map((input) =>
        input.editable === "root" ? { ...input, editable: true } : input
      );
    setInputConfig(inputsToShow);
    // eslint-disable-next-line
  }, [userData.userType, userData.tenantConfig]);

  useEffect(() => {
    userData.tenant &&
      getTenantData({
        setFetchError,
        setFetchLoading,
        tenantId,
        setTenantData,
        setInputValues,
        userData
      });
    // eslint-disable-next-line
  }, [userData?.tenant]);

  return (
    <div className={`tenant-details-modal-container  ${updateError && "update-error"}`}>
      <Modal close={() => closeFunction()}>
        {fetchLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : fetchError ? (
          <img src={ErrorRobot} className="error-img" alt="Error" />
        ) : (
          <>
            <div className="modal-header">{`Info: ${
              tenantData.friendly_name || tenantData.tenant
            }`}</div>
            <ul className="modal-input-container">
              {inputConfig.map((input) => (
                <li className="modal-input" key={input.value}>
                  <div
                    className={`input-label ${!input.editable && "read-only"}`}
                  >
                    {input.label}
                  </div>
                  <div className="input-field-container">
                    {input.editable ? (
                      renderInput({input, inputValues, setInputValues, showDropdown, setShowDropdown})
                    ) : (
                      <div className="input-field read-only">
                        {input.value === "log_management"
                          ? renderLogManagementValue(tenantData.log_management?.enabled)
                          : tenantData[input.value]}
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            {updateError && (
              <div className={`submit-error ${updateError.length > 60 && "text-wrapped"}`}>
                <ErrorIcon className="err-icon" />
                {updateError}
              </div>
            )}
            <LoaderButton
              loading={updateLoading}
              onClick={() =>
                handleUpdate({
                  setUpdateError,
                  setUpdateLoading,
                  inputValues,
                  valueMapping,
                  tenantId,
                  refreshData,
                  closeFunction,
                  userData,
                  tenantData
                })
              }
              text={"SAVE"}
              className="save-btn"
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default TenantDetailsModal;
