const rootStyles = getComputedStyle(document.documentElement);

export const statuses = [
  {'Re-Opened': rootStyles.getPropertyValue('--yellow').trim()},
  {'Waiting_On_ActZero': rootStyles.getPropertyValue('--green').trim()},
  {'Waiting_On_Customer': rootStyles.getPropertyValue('--orange').trim()},
  {'Resolved': rootStyles.getPropertyValue('--blue').trim()},
  {'Merged': rootStyles.getPropertyValue('--blue').trim()},
  {'Closed': rootStyles.getPropertyValue('--blue').trim()},
]
