import { useCallback, useEffect, useRef, useState, useContext } from "react";
import "../../../styles/components/D3/ForceDirectionGraph/ForceDirectionGraph.scss";
import { ForceGraph3D } from "react-force-graph";
import ExpandedDetails from "../../../pages/KnowledgeGraph/ExpandedDetails";
import { handleDataClick, renderNodes } from "./util";
import { nodeColors } from "../../../pages/KnowledgeGraph/config/filterConfig";
import * as d3 from "d3";
import { CSS3DRenderer } from "three/addons/renderers/CSS3DRenderer.js";
import ZoomButtons from "../../Graph/ZoomButtons";
import { resize } from "../../../util/resize";
import { forceManyBody, forceX, forceY, forceZ } from "d3-force-3d";
import * as THREE from "three";
import { CssLoader } from "../../Loader/CssLoader";
import { isRoot } from "../../../util/userControl";
import UserData from "../../../store/User/UserData";
import Tooltip from "../../../components/Tooltip/Tooltip";
import TooltipIcon from "../../../assets/icons/tooltip.svg";

const labelVisibilityThreshold = 3000; // zoom distance to show labels
const performanceThreshold = 3000; // disable forces & drag when too many nodes/links
const navInstructions = {
  3: (
    <div>
      <div>Left-click: rotate graph, focus nodes and links</div>
      <div>Right-click: pan graph, highlight nodes and links</div>
      <div>Scroll to zoom</div>
    </div>
  ),
  2: (
    <div>
      <div>Left-click: drag graph, focus nodes and links</div>
      <div>Right-click: highlight nodes and links</div>
      <div>Scroll to zoom</div>
    </div>
  ),
};

const ForceDirectionGraph = ({ data, dimensions, setDimensions }) => {
  const [userData] = useContext(UserData);
  const fgRef = useRef();
  const containerRef = useRef();
  const expandedViewRef = useRef();
  const hoverExpandedViewRef = useRef();
  const [selectedData, setSelectedData] = useState();
  const [hoverData, setHoverData] = useState();
  const [highlightedSelectData, setHighlightedSelectData] = useState(new Set());
  const [highlightedHoverData, setHighlightedHoverData] = useState(new Set());
  const nodeLabelToggled = useRef(false);
  const [zoomDimensions, setZoomDimensions] = useState({ width: 0, height: 0 });
  const [loading, setLoading] = useState(true);
  const [initialRenderSet, setInitialRenderSet] = useState(false);
  const [cooldownTicks, setCooldownTicks] = useState(0);
  const [trackedData, setTrackedData] = useState([]);

  useEffect(() => {
    resize(0, containerRef, setZoomDimensions);
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => data && renderNodes({ data }), [data]); // pre-rendered nodes

  useEffect(() => {
    let listener;
    const ref = containerRef.current;
    if (dimensions === 2) {
      listener = ref.addEventListener("wheel", handleLabelToggle, {
        passive: true,
      });
    }
    return () => listener && ref.removeEventListener("wheel", listener);
    // eslint-disable-next-line
  }, []);

  const handleLabelToggle = () => {
    if (!nodeLabelToggled.current) {
      if (fgRef.current.cameraPosition().z < labelVisibilityThreshold) {
        fgRef.current.refresh();
        nodeLabelToggled.current = true;
      }
    } else if (fgRef.current.cameraPosition().z >= labelVisibilityThreshold) {
      fgRef.current.refresh();
      nodeLabelToggled.current = false;
    }
  };

  useEffect(() => {
    // initial camera positioning
    if (initialRenderSet) {
      if (dimensions === 3)
        setTimeout(() => fgRef.current.zoomToFit(1000), 100);
      else {
        const distance =
          data.nodes.length > 10
            ? labelVisibilityThreshold - 50
            : labelVisibilityThreshold - 1000;
        const mostConnected = data.nodes.reduce(
          (prev, node) => {
            return node?.neighbors?.length > prev?.neighbors?.length
              ? node
              : prev;
          },
          { neighbors: [] }
        );
        const lookAt = {
          x: mostConnected.x,
          y: mostConnected.y,
        };
        fgRef.current.cameraPosition({ ...lookAt, z: distance }, lookAt);
      }
      setCooldownTicks(Infinity);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [data, initialRenderSet]);

  useEffect(() => {
    if (selectedData) {
      selectedData.source
        ? handleLinkHighlights(selectedData, setHighlightedSelectData)
        : handleNodeHighlights(selectedData, setHighlightedSelectData);
    }
  }, [selectedData]);

  useEffect(() => {
    const graph = fgRef.current;
    const controls = graph.controls();
    controls.dynamicDampingFactor = 0.7; // drag senstitivity
    if (dimensions === 2) {
      controls.noRotate = true;
      controls.mouseButtons = {
        LEFT: 2,
        MIDDLE: 1,
        RIGHT: 0,
      };
      graph.d3Force(
        "charge",
        d3.forceManyBody().strength(Math.min(-3500, data.links.length * -2))
      );
      graph.d3Force("x", d3.forceX());
      graph.d3Force("y", d3.forceY());
      graph.d3Force("link").distance(250);
      graph.d3Force("collide", d3.forceCollide(75));
    } else {
      controls.noRotate = false;
      controls.mouseButtons = {
        LEFT: 0,
        MIDDLE: 1,
        RIGHT: 2,
      };
      graph.d3Force(
        "charge",
        forceManyBody().strength(Math.min(-4000, data.links.length * -3))
      );
      graph.d3Force("x", forceX());
      graph.d3Force("y", forceY());
      graph.d3Force("z", forceZ());
      graph.d3Force("link").distance(250);
    }

    if (containerRef.current) {
      containerRef.current.addEventListener("mousedown", (e) => {
        if (
          expandedViewRef.current &&
          !expandedViewRef.current.contains(e.target)
        ) {
          setSelectedData();
        }
      });
    }
    // eslint-disable-next-line
  }, [data]);

  const handleClick = useCallback(
    (data) => {
      const graph = fgRef.current;
      handleDataClick({
        data,
        graph,
        dimensions,
        setSelectedData,
        setHoverData,
      });
    },
    // eslint-disable-next-line
    [fgRef]
  );

  const addHighlightedData = (dataToHighlight) =>
    setHighlightedSelectData(
      new Set([...highlightedSelectData, ...dataToHighlight])
    );

  const removeHighlightedData = (dataToHighlight) =>
    setHighlightedSelectData((prev) => {
      prev.forEach((data) =>
        dataToHighlight.has(data) ? prev.delete(data) : prev
      );
      return prev;
    });

  const handleRightClick = (data) => {
    let setFunction;
    if (trackedData.includes(data.id)) {
      setTrackedData(trackedData.filter((id) => id !== data.id));
      setFunction = removeHighlightedData;
    } else {
      setTrackedData([...trackedData, data.id]);
      setFunction = addHighlightedData;
    }

    if (data) {
      data.source
        ? handleLinkHighlights(data, setFunction)
        : handleNodeHighlights(data, setFunction);
    }
  };

  const paintNode = useCallback(
    (node) => {
      const icons = node.icons;
      const icon = highlightedSelectData.has(node.id)
        ? icons.selectIcon
        : highlightedHoverData.has(node.id)
        ? icons.hoverIcon
        : icons.icon;
      if (
        fgRef.current.cameraPosition().z < labelVisibilityThreshold &&
        dimensions === 2
      ) {
        icon.add(icons.label);
      }
      return icon;
    },
    // eslint-disable-next-line
    [highlightedHoverData, highlightedSelectData]
  );

  const handleNodeHighlights = (node, setFunction) => {
    const dataToHighlight = new Set();
    if (node) {
      dataToHighlight.add(node.id);
      node.neighbors.forEach((neighbor) => dataToHighlight.add(neighbor.id));
      node.links.forEach((link) => dataToHighlight.add(link.id));
    }
    setFunction(dataToHighlight);
  };

  const handleLinkHighlights = (link, setFunction) => {
    const dataToHighlight = new Set();
    if (link) {
      dataToHighlight.add(link.id);
      dataToHighlight.add(link.source.id);
      dataToHighlight.add(link.target.id);
    }
    setFunction(dataToHighlight);
  };

  const handleZoomButton = (value) => {
    const camera = fgRef.current.camera();
    const multiplier = value === "zoom-in" ? 0.8 : 1.2;
    if (dimensions === 3) {
      const coords = fgRef.current.cameraPosition();
      const vect = new THREE.Vector3(coords.x, coords.y, coords.z);
      fgRef.current.cameraPosition(vect.multiplyScalar(multiplier));
    } else {
      camera.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z * multiplier
      );
      handleLabelToggle();
    }
  };

  const prevHover = useRef();
  const extraRenderers = [new CSS3DRenderer()];

  const rootStyles = getComputedStyle(document.documentElement);
  const bgColor =  rootStyles.getPropertyValue('--background').trim()
  const grey =  rootStyles.getPropertyValue('--grey').trim()

  return (
    <div className="fdg-container" ref={containerRef}>
      <div ref={expandedViewRef}>
        {selectedData && (
          <ExpandedDetails
            data={selectedData}
            coords={{
              x: containerRef.current.offsetWidth / 2 + 50,
              y: containerRef.current.offsetHeight / 2 - 60,
            }}
            showLinks
          />
        )}
        <div ref={hoverExpandedViewRef}>
          {hoverData && <ExpandedDetails data={hoverData} trackCursor hover />}
        </div>
      </div>
      {isRoot(userData?.userType) && (
        <div className="switch-container">
          <div className="dim-button" onClick={setDimensions}>{`${
            dimensions === 3 ? 2 : 3
          }D`}</div>
        </div>
      )}
      <Tooltip
        content={navInstructions[dimensions]}
        direction="left"
        disableTimeout
      >
        <img className="tooltip-icon" src={TooltipIcon} alt="Controls" />
      </Tooltip>
      <ZoomButtons handleZoomButton={handleZoomButton} />
      {data && (
        <>
          {loading && <CssLoader className={"loader-custom"} />}
          <ForceGraph3D
            // Graph
            ref={fgRef}
            extraRenderers={extraRenderers}
            graphData={data}
            numDimensions={dimensions}
            width={zoomDimensions.width}
            height={zoomDimensions.height}
            warmupTicks={250}
            enableNodeDrag={
              Math.max(data?.nodes?.length, data?.links?.length) <
              performanceThreshold
            }
            cooldownTicks={
              Math.max(data?.nodes?.length, data?.links?.length) <
              performanceThreshold
                ? cooldownTicks
                : 0
            } // setting to 0 disables forces for better performance
            d3AlphaDecay={initialRenderSet ? 0.4 : null}
            d3VelocityDecay={0.4}
            backgroundColor={bgColor}
            showNavInfo={false}
            onBackgroundClick={() => {
              setHighlightedSelectData(new Set());
              setHighlightedHoverData(new Set());
            }}
            onEngineStop={() => setInitialRenderSet(true)}
            // Links
            onLinkHover={(link) => {
              if (prevHover.current !== link?.id) {
                if (selectedData && selectedData?.id === link?.id) return;
                setHoverData(link);
                prevHover.current = link?.id;
                handleLinkHighlights(link, setHighlightedHoverData);
              }
            }}
            onLinkClick={handleClick}
            linkWidth={(link) =>
              link?.properties.hasOwnProperty("severity") &&
              (highlightedSelectData.has(link.id) ||
                highlightedHoverData.has(link.id))
                ? 10
                : highlightedSelectData.has(link.id)
                ? 6
                : 4
            }
            linkColor={(link) =>
              link?.properties.hasOwnProperty("severity")
                ? nodeColors[link?.properties?.severity.toLowerCase()]
                : highlightedSelectData.has(link.id)
                ? "#C0D0D0"
                : highlightedHoverData.has(link.id)
                ? "#ECEFEF"
                : grey
            }
            linkOpacity={1}
            onLinkRightClick={handleRightClick}
            // Nodes
            onNodeClick={handleClick}
            onNodeHover={(node) => {
              if (prevHover.current !== node?.id) {
                if (selectedData && selectedData?.id === node?.id) return;
                setHoverData(node);
                prevHover.current = node?.id;
                handleNodeHighlights(node, setHighlightedHoverData);
              }
            }}
            nodeThreeObject={paintNode}
            onNodeRightClick={handleRightClick}
            onNodeDragEnd={(node) => {
              node.fx = node.x;
              node.fy = node.y;
              node.fz = node.z;
            }}
          />
        </>
      )}
    </div>
  );
};

export default ForceDirectionGraph;
