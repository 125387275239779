import React, { useState, useContext, useMemo } from "react";
import UserData from "../../../store/User/UserData";
import Modal from "../../../components/Modal/Modal";
import LoaderButton from "../../../components/Button/LoaderButton";
import { captureSentryError } from "../../../util/sentry";
import Highlighter from "react-highlight-words";
import { postAIEFeedback } from "../../../api/ApiClient";

const CommentModal = ({ data, handleClose, traceID }) => {
  const [userData] = useContext(UserData);
  const [selectedText, setSelectedText] = useState("");
  const [comment, setComment] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  // Remove emojis and extra spaces from text so highlight function works properly
  const text = useMemo(
    () =>
      data?.text
        ?.toString()
        .replace(/[\p{Extended_Pictographic}\uFE0F]+/gu, "")
        .replace(/\s+/g, " ")
        .trim(),
    [data?.text]
  );

  const handleCommentSubmit = async () => {
    try {
      setSubmitLoading(true);
      const feedbackBody = {
        score_name: data.module,
        score_value: data.scoreValue || 0.5,
        agent: "adhoc",
        score_comment: comment,
        target_text: selectedText || text,
      };
      await postAIEFeedback(userData.tenant, traceID, feedbackBody);
      handleClose();
    } catch (e) {
      captureSentryError(e, userData, "POST adhoc investigation comment");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleTextSelection = () => {
    const selected = window.getSelection().toString();
    setSelectedText(selected);
  };

  return (
    <Modal close={() => handleClose()}>
      <div className="modal-content">
        <div className="modal-header">{`${data?.module} - Feedback`}</div>
        <div className="text-box" onMouseUp={handleTextSelection}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[selectedText]}
            autoEscape={true}
            textToHighlight={text || ""}
          />
        </div>
        <textarea
          placeholder="Write your comment here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <LoaderButton
          onClick={() => handleCommentSubmit()}
          loading={submitLoading}
          text="SUBMIT"
        />
      </div>
    </Modal>
  );
};

export default CommentModal;
