import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {isAZUser} from "../util/userControl";
import UserData from "../store/User/UserData";
import Beta from '../assets/icons/beta2.svg';

const DropdownNav = ({
                       data,
                       activeNavItem,
                       setActiveNavItem,
                       indent,
                       className,
                       handleClickTrack,
                       activeDropdown,
                       disableMDRLiteAndMobile = () => {},
                     }) => {
  const [userData] = useContext(UserData);
  return data.map((sub, index) => {
    return (
      sub.featureflag && !isAZUser(userData) ? <></> :
        <div key={index}>
          {sub.path ? (
            <Link
              className={(sub.class ? ` ${sub.class}` : '')}
              to={disableMDRLiteAndMobile(sub.menuText) !== " disabled-nav" && sub.path}
              key={sub.path}
            >
            <span
              className={
                className + (sub.class ? ` ${sub.class}` : '') +
                (sub.menuText === "SUPPORT" ? " help-btn sub-item path " : " nav-item sub-item path ") +
                (sub.menuText === "SUPPORT" ? "is-active " : " ") +
                (activeNavItem === sub.menuText ? "is-active" : "") +
                disableMDRLiteAndMobile(sub.menuText)
              }
              onClick={() => setActiveNavItem(sub.menuText)}
            >
              <span className={"text " + sub.menuText}>
                {sub.menuText}
                {sub.menuText === 'ZEROIN' && <img className={"asc"} src={Beta} alt=""/>}
              </span>
            </span>
            </Link>
          ) : sub.link ? (
            <a
              href={sub.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => handleClickTrack(`${activeDropdown} menu - ${sub.menuText}`)}
            >
            <span
              className={className + " help-btn sub-item path " + (activeNavItem === sub.menuText ? "is-active" : "")}
            >
              {sub.menuText}
            </span>
            </a>
          ) : (<span
            className={className + " nav-item sub-item no-select" + (sub.class ? ` ${sub.class}` : '')}
          >{sub.menuText}</span>)}
          {sub?.sub && (
            <DropdownNav
              className={"sub-sub-item"}
              data={sub.sub}
              activeNavItem={activeNavItem}
              setActiveNavItem={setActiveNavItem}
              indent={indent + 25}
            />
          )}
        </div>
    );
  });
};

export default DropdownNav;
