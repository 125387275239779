import React from 'react';
import '../../styles/pages/Detections/ExpandedView.scss';
import StatusBar from "../../components/Table/StatusBar";
import {stringifyObject} from "../../util/format.js"
import {formatField} from "../../util/transform.js"

import CustomizedTreeView from "../../components/Table/Tree";

const ExpandedView = ({
                        className,
                        data,
                        severityImage,
                        severityName,
                        status,
                        statuses,
                        source,
                        leftColumn,
                        rightColumn
                      }) => {
  const hasProcessTree = (source && source === 'Endpoint / CrowdStrike')
  const getDetectionId = () => {
    return data['Detection ID']
  }

  return (
    <div className={(className ? className : '') + " expanded-view-container"}>
      {hasProcessTree ?
        <div className={"left-container endpoint"}>
          <CustomizedTreeView detectionId={getDetectionId()}/>
        </div> :
        <table className={"left-container"}>
          <tbody>
          {Object.keys(leftColumn)?.map((title, titleIndex) => {
            let items = leftColumn[title]
            let is_title_added = false
            return Object.values(items).map((item, index) => {
              let {fieldName, displayName, value} = formatField(data, item, index)
              if (index === 0 || !is_title_added) {
                if (value) {
                  is_title_added = true
                }
                return value &&
                  [<tr className={"expanded-item"} key={"l" + titleIndex}>
                    <td className={"expanded-item-title-bold"}>{title}</td>
                  </tr>,
                    <tr className={"expanded-item"} key={"l" + index + titleIndex}>
                      <td className={"expanded-item-title"}>{displayName || fieldName}</td>
                      <td className={"expanded-item-value"}>{value}</td>
                    </tr>]
              } else {
                return value &&
                  <tr className={"expanded-item"} key={"l" + index + titleIndex}>
                    <td className={"expanded-item-title"}>{displayName || fieldName}</td>
                    <td className={"expanded-item-value"}>{value}</td>
                  </tr>
              }
            })
          })}
          </tbody>
        </table>
      }


      <div className={"right-container-height"}>
        <table className={"right-container"}>
          <tbody>
          {status && status !== '' ? <tr key={'status-bar'} className={"status-bar"}>
            <td>STATUS</td>
            <td>
              <StatusBar className={"detections"} statuses={statuses} selectedStatus={status}/>
            </td>
          </tr> : null}
          {severityName && severityName !== '' ? <tr className={"severity"}>
            <td className={"severity-icon"}>
              <img alt={severityImage} src={severityImage}/>
            </td>
            <td>
              <p className={"severity-title"}>{severityName}</p>
            </td>
          </tr> : null}
          {Object.keys(rightColumn).map((title, titleIndex) => {
            let items = rightColumn[title]
            let is_title_added = false

            return Object.values(items).map((item, index) => {
              const {fieldName, displayName, value} = formatField(data, item, index)
              if (index === 0 || !is_title_added) {
                if (value) {
                  is_title_added = true
                }
                return value &&
                  [<tr className={"expanded-item"} key={"r" + titleIndex}>
                    <td className={"expanded-item-title-bold"}>{title}</td>
                  </tr>,
                    <tr className={"expanded-item"} key={"r" + index + titleIndex}>
                      <td className={"expanded-item-title"}>{displayName || fieldName}</td>
                      <td className={"expanded-item-value"}>{item.skipStringify ? value : stringifyObject(value)}</td>
                    </tr>]
              } else {
                return value &&
                  <tr className={"expanded-item"} key={"r" + index + "" + titleIndex}>
                    <td className={"expanded-item-title"}>{displayName || fieldName}</td>
                    <td
                      className={"expanded-item-value " + (displayName || fieldName)}>{item.skipStringify ? value : stringifyObject(value)}</td>
                  </tr>
              }
            })
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default ExpandedView;
