import React, {useContext, useEffect, useState, useRef} from 'react';
import '../../../styles/pages/Hosts/Updates.scss';
import PageFilters from "../../../components/Filters/PageFilters";
import GlobalState from "../../../store/GlobalState/GlobalState";
import PaginationControls from "../../../components/Table/PaginationControls";
import {resetUpdatesFilterConfig, updatesFilterConfig} from "../filterconfig";
import Table from "../../../components/Table/Table";
import {Loader} from "../../../components/Loader/Loader";
import {Pagination} from "../../../components";
import {filterData} from "../../../util/format.js"
import UpdatesRow from "./UpdatesRow";
import {downloadFile} from "../../../util/export"
import UserData from "../../../store/User/UserData";
import {mainRow} from "../../../components/Mappings/MissingUpdates";
import TableHeader from "../../../components/Table/TableHeader";
import moment from 'moment';
import {getMissingUpdates} from "../../../api/ApiClient";
import {useLocation} from "react-router-dom";
import {setSortFilterValue} from "../../../util/handleSortResults";
import {setResetFilter} from "../../../util/handleResetFilter";
import {trackPageView} from "../../../util/analytics";
import NotFound from "../../../navigation/NotFound";
import _ from 'lodash';
import { captureSentryError } from '../../../util/sentry.js';

const Updates = ({setCount}) => {
  const [userData] = useContext(UserData);
  const [azState] = useContext(GlobalState);
  const location = useLocation()
  const [pageCount] = useState(50)
  const today = new Date()
  const defaultStartTime = new Date(new Date().setDate(today.getDate() - 30))
  const [filterList, setOpenFilters] = useState(updatesFilterConfig)
  const [updatesParams, setUpdatesParams] = useState({})
  const [page, setPage] = useState(1)
  let [sortFilters, setSortFilters] = useState([])
  const [expandAll, setExpandAll] = useState([])
  const [showDropdown, toggleShowDropdown] = useState('')
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [updatesLoading, setUpdatesLoading] = useState(false);
  const [updatesData, setUpdatesData] = useState({results: [], total: 0})
  const [initialConfig, setInitialConfig] = useState(location.state?.hostname ? {
    q: location.state?.hostname,
    page: 1,
    size: pageCount
  } : {start_time: moment(defaultStartTime).format(), end_time: moment(today).format(), page: 1, size: pageCount})
  const [exportLoading, setExportLoading] = useState(false);
  const [defaultDate, setDefaultDate] = useState(location.state?.hostname ? {} : {text: 'Last 30 Days', time: defaultStartTime})
  const [isReset, setIsReset] = useState(false)
  const [selectedDate, setSelectedDate] = useState(location.state?.hostname ? '' : 'Last 30 Days')
  const [fetchError, setFetchError] = useState(false);
  const prevParams = useRef({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("MissingUpdates", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  const resetAllFilters = () => {
    setIsReset(true)
    handleClose()
    setSortFilterValue([], mainRow, setSortFilters)
    setResetFilter(updatesFilterConfig, resetUpdatesFilterConfig, setOpenFilters)
    setUpdatesParams({
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount
    })
    setDefaultDate({text: 'Last 30 days', time: defaultStartTime})
    setInitialConfig({
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount
    })
    setSelectedDate('Last 30 Days')
    window.history.replaceState(null, location.state?.hostname)
    setIsReset(false)
  }

  const handleClose = () => {
    setExpandAll([])
    setExpandedIndexes([])
  }

  const handleChangePage = async (currPage) => {
    setPage(currPage)
    setUpdatesParams({...updatesParams, page: currPage})
  }

  useEffect(() => {
    setSortFilterValue([], mainRow, setSortFilters)
    setResetFilter(updatesFilterConfig, resetUpdatesFilterConfig, setOpenFilters)
  }, [])

  useEffect(() => {
    setPage(1)
    filterData(filterList, updatesParams, pageCount, 1, setUpdatesParams, initialConfig, setInitialConfig)
    // eslint-disable-next-line
  }, [filterList, pageCount])

  useEffect(() => {
    const getUpdatesData = async () => {
      setFetchError(false);
      let isExport = updatesParams.isExport || false
      if (isExport) {
        setUpdatesParams({...updatesParams, isExport: false})
        setExportLoading(true)
      } else setUpdatesLoading(true)
      try {
        const updatesResponse = await getMissingUpdates(userData?.tenant, updatesParams, isExport)
        if (isExport) {
          downloadFile(updatesResponse)
          setExportLoading(false)
        } else {
          setUpdatesData(updatesResponse)
          setCount(updatesResponse?.total || 0)
        }
      } catch (e) {
        captureSentryError(e, userData, 'getMissingUpdates API in Updates.js');
        setFetchError(true);
      } finally {
        setUpdatesLoading(false)
      }
    }
    if(!_.isEqual(prevParams.current, updatesParams)) {
      userData?.tenant && Object.keys(updatesParams).length > 0 && getUpdatesData();
      prevParams.current = {...updatesParams};
    }
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, updatesParams, setCount]);


  return (
    <div className={"updates-container"}>
      <PageFilters
        isReset={isReset}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        className={"hosts-filters"}
        handleClose={handleClose}
        filterList={filterList}
        setOpenFilters={setOpenFilters}
        setParams={setUpdatesParams}
        setExpandAll={setExpandAll}
        defaultDate={defaultDate}
        initialSearch={location.state?.hostname}
      />
      {
        !updatesLoading &&
        <PaginationControls
          resetAllFilters={resetAllFilters}
          setExpandAll={setExpandAll}
          displayData={updatesData}
          page={page}
          setPage={handleChangePage}
          expandAll={expandAll}
          showDropdown={showDropdown}
          toggleShowDropdown={toggleShowDropdown}
          setParams={setUpdatesParams}
          params={updatesParams}
          setExpandedIndexes={setExpandedIndexes}
          expandedIndexes={expandedIndexes}
          bauExportLoading={exportLoading}
          className={"updates-pagination"}
        />
      }
      {updatesLoading ?
        <div className={"loader-container"}><Loader/></div>
        : fetchError ? <NotFound isError dataError />
        : !updatesLoading && updatesData.results.length === 0 ?
          <div>
            <p className={'line-filter'}/>
            <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
          </div>
          :
          <div>
            <div className="fixed-table-container">
              <Table page={"updates"}>
                <TableHeader
                  mainRow={mainRow}
                  className={"missing-updates"}
                  setSortFilters={setSortFilters}
                  sortFilters={sortFilters}
                  setParams={setUpdatesParams}
                  params={updatesParams}
                />
                {
                  updatesData.results.map((item, i) => <UpdatesRow
                    key={i}
                    page={"updates"}
                    data={item}
                    onClick={async () => {
                    }}
                    index={i}
                    setExpandedIndexes={setExpandedIndexes}
                    expandedIndexes={expandedIndexes}
                    setExpandAll={setExpandAll}
                    expandAll={expandAll}
                    isExpanded={(expandedIndexes?.includes(i) || expandAll?.includes(i))}
                  />)
                }
              </Table>
            </div>
            <div className={"pagination-controls"}>
              <Pagination
                setPage={handleChangePage}
                page={page}
                total={updatesData.total}
              />
            </div>
          </div>
      }
    </div>
  )

}

export default Updates;