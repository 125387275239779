export const helpConfig = [
        {path: '/help/support-ticket', menuText: 'SUPPORT'},
        {link: 'https://docs.actzero.ai/', menuText: 'DOCUMENTATION'},
        {link: 'https://docs.actzero.ai/release-notes/', menuText: 'RELEASE NOTES'},
        {link: 'https://docs.actzero.ai/endpoints/crowdstrike/install-agent/', menuText: 'AGENT INSTALLER'},
        {link: 'https://forms.gle/mzWPHoAZYCKE2iyXA', menuText: 'SEND FEEDBACK'}
]

export const wgHelpConfig = [
        {link: 'https://www.watchguard.com/help/docs/help-center/en-US/index_CSH.html#15130', menuText: 'DOCUMENTATION'},
        {link: 'https://www.watchguard.com/support/release-notes/Cloud/Content/en-US/MDR/EN_release-notes_MDR.html', menuText: 'RELEASE NOTES'},
]
