const navigationConfig = [
  {path: '/', menuText: 'DASHBOARD'},
  {
    menuText: 'ACTIVITY',
    sub: [
      {path: '/activity/investigations', menuText: 'INVESTIGATIONS'},
      {path: '/activity/detections', menuText: 'DETECTIONS'},
      {path: '/activity/log-search', menuText: 'LOG SEARCH'},
      {path: '/activity/network', menuText: 'NETWORK'},
      {path: '/activity/zeroin', menuText: 'ZEROIN'}
    ]
  }, {
    menuText: 'RISK MANAGEMENT',
    sub: [
      {path: '/risk-management/vulnerabilities', menuText: 'VULNERABILITIES'},
      {path: '/risk-management/cloud-posture', menuText: 'CLOUD POSTURE'},
      {path: '/risk-management/maturity-assessment', menuText: 'MATURITY ASSESSMENT'},
    ]
  },
  {menuText: 'REPORTS', sub: [{path: '/reports/files', menuText: 'FILES'}]}, 
  {
    menuText: 'CONNECTIONS',
    sub: [
      {path: '/connections/endpoints', menuText: 'ENDPOINTS'},
      {path: '/connections/mobile-devices', menuText: 'MOBILE DEVICES'},
      {path: '/connections/service-status', menuText: 'SERVICE STATUS'},
    ]
  }
]

export const watchguardNavConfig = [
  {path: '/', menuText: 'DASHBOARD'},
  {
    menuText: 'ACTIVITY',
    sub: [
      {path: '/activity/investigations', menuText: 'INVESTIGATIONS'},
      {path: '/activity/detections', menuText: 'DETECTIONS'},
    ]
  }, {
    menuText: 'RISK MANAGEMENT',
    sub: [
      {path: '', menuText: 'VULNERABILITIES', class: "disabled-nav"},
      {path: '', menuText: 'CLOUD POSTURE', class: "disabled-nav"},
      {path: '', menuText: 'MATURITY ASSESSMENT', class: "disabled-nav"},
    ]
  },
  {menuText: 'REPORTS', sub: [{path: '/reports/files', menuText: 'FILES'}]},
  {
    menuText: 'CONNECTIONS',
    sub: [
      {path: '/connections/endpoints', menuText: 'ENDPOINTS', class: "disabled-nav disable-click"},
      {path: '/connections/service-status', menuText: 'SERVICE STATUS'},
    ]
  }
]

export const parentNavigationConfig = [
  {path: '/', menuText: 'DASHBOARD'},
  {menuText: 'ACTIVITY'},
  {menuText: 'RISK MANAGEMENT'},
  {menuText: 'REPORTS'},
  {menuText: 'CONNECTIONS'}
]

export const toolsMenuConfig = [
      {path: '/onboarding', menuText: 'ONBOARDING'}
]

export const rootToolsMenuConfig = [
  {path: '/onboarding', menuText: 'ONBOARDING'},
  {path: '/onboarding/tenants/create', menuText: 'ADD TENANT'}
]

export default navigationConfig;
