import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../../../styles/pages/Onboarding/UploadModal.scss";
import { FileUploader } from "react-drag-drop-files";
import UploadIcon from "../../../assets/icons/file-upload-icon.svg";
import _ from "lodash";
import { Loader } from "../../../components/Loader/Loader";
import Button from "../../../components/Button/Button";

const fileTypes = ["CERT", "PEM", "DER", "KEY", "CRT", "CER", "TXT"];
const fileTypesMIME = ["application/x-x509-ca-cert", "application/x-iwork-keynote-sffkey", "application/pkix-cert", "application/x-x509-ca-cert", "application/x-x509-user-cert", "application/x-pkcs8", "application/x-pkcs12", "application/x-pem-file", "text/plain"];
const errorMessage =
  "Error uploading the file. Please try again. If the problem persists, please reach out to customer-support@actzero.ai.";

export const UploadModal = ({isOpen, setOpen, fileNames, setFileNames, uploadFileName, setResetEditModal, editFileNames, setEditFileNames, uploadEditFileName, isEdit}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [incompatibleFileMsg, setIncompatibleFileMsg] = useState(false);

  const handleClose = () => {
    setFiles([]);
    setErrors([]);
    setOpen(false);
    setIncompatibleFileMsg(false);
    setLoading(false);
    setResetEditModal(true);
  };

  const isIncompatibleFile = (file) =>
    (file.type && !fileTypesMIME.includes(file.type)) ||
    (!file.type &&
      !fileTypes.includes(file.name.split(".").pop().toUpperCase()));

  const addFiles = (files) => {
    const filtered = [...files].filter((f) => {
      if (isIncompatibleFile(f)) {
        setIncompatibleFileMsg(true);
        return false;
      }
      return true;
    });
    setFiles(() => [...filtered]);
  };

  const addFileUpload = (file) => {
    if (isIncompatibleFile(file)) {
        setIncompatibleFileMsg(true);
    } else{
      setFiles(() => [file]);
    }
  }

  const deleteFile = (file) => {
    setFiles((prevState) => prevState.filter((f) => f.name !== file.name));
  };

  const handleUpload = async () => {
    try {
      for (let i in files) {
        if(isEdit){
          let uploadFile = editFileNames
          uploadFile[uploadEditFileName] = files[i]
          setEditFileNames(uploadFile);
        } else{
          let uploadFile = fileNames
          uploadFile[uploadFileName] = files[i]
          setFileNames(uploadFile);
        }
        setOpen(false);
      }
    } catch (e) {
      setErrors(errorMessage);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableAutoFocus
      disableScrollLock
    >
      <Box className="uploadCSV-modal-container" sx={{ boxShadow: 24 }}>
        <div className="title-container">
          <span className="title">File upload</span>
        </div>
        {loading ? (
          <Loader />
        ) : errors.length ? (
          <div className="error-msg">
            {Array.isArray(errors) ? (
              <ul>
                <li>There was a problem uploading the file(s):</li>
                {errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            ) : (
              errors
            )}
          </div>
        ) : (
          <div className="upload-content-container">
            {incompatibleFileMsg && (
              <span className="incompatible-msg">
                {"Incompatible file type added."} <br />{" "}
                {"Accepted file types: " + fileTypes.join(", ") + "."}
              </span>
            )}
            <FileUploader
              handleChange={addFileUpload}
              name="file"
              types={fileTypes}
              hoverTitle=" "
            >
              <div className="dragdrop-area">
                <div className="dragdrop-content">
                  <img className="upload-icon" src={UploadIcon} alt="Upload" />
                  <span className="dragdrop-text">
                    Drag and drop file to upload
                  </span>
                </div>
              </div>
            </FileUploader>
            <div className="files-btn-container">
              <span className="upload-text">{"or"}</span>
              <IconButton>
                <label className="upload-btn" htmlFor="select-files">
                  CHOOSE FILE
                </label>
                <input
                  type="file"
                  id="select-files"
                  onChange={(e) => {
                    addFiles(e.target.files);
                    e.target.value = null;
                  }}
                  accept={[...fileTypes.map(f => `.${f.toLowerCase()}`), ...fileTypesMIME].join()}
                />
              </IconButton>
            </div>
            <div className="file-list-container">
              <ul className="file-list">
                {files.map((f) => {
                  return (
                    <li className="file-list-item" key={_.uniqueId()}>
                      {f.name}
                      <button onClick={() => deleteFile(f)}>x</button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="upload-btn-container">
              <Button
                className={"files-button border-btn"}
                disabled={!files.length}
                text={'UPLOAD'}
                onClick={() => handleUpload()}
              />
            </div>
          </div>
        )}
        <IconButton
          aria-label="Close"
          onClick={handleClose}
          className="close-button-container"
        >
          <CloseIcon className="close-icon" fontSize="small" />
        </IconButton>
      </Box>
    </Modal>
  );
}
