import React, { useState, useContext } from "react";
import { postAIEFeedback } from "../../../api/ApiClient";
import { captureSentryError } from "../../../util/sentry";
import UserData from "../../../store/User/UserData";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import CommentIcon from "@mui/icons-material/Comment";
import "../../../styles/pages/AdhocInvestigation/FeedbackButtons.scss";

const FeedbackButtons = ({
  traceID,
  sectionData,
  setCommentModalData,
  agent,
}) => {
  const [userData] = useContext(UserData);
  const [likeStatus, setLikeStatus] = useState(null);
  const [scoreValue, setScoreValue] = useState(0.5);

  const handleLikeDislike = async (type) => {
    try {
      // Like = 1, Dislike = 0, Neutral = 0.5
      const updatedScore = likeStatus === type ? 0.5 : type === "like" ? 1 : 0;
      setLikeStatus(prev => (prev === type ? null : type));
      setScoreValue(updatedScore);
      const feedbackBody = {
        score_name: sectionData.module,
        score_value: updatedScore,
        agent: agent,
      };
      await postAIEFeedback(userData.tenant, traceID, feedbackBody);
    } catch (e) {
      captureSentryError(e, userData, `Error ${type}ing in ActionPanel`);
    }
  };

  const openCommentModal = () =>
    setCommentModalData({ ...sectionData, scoreValue });

  const actions = [
    {
      onClick: () => handleLikeDislike("like"),
      icon:
        likeStatus === "like" ? (
          <ThumbUpAltIcon fontSize="inherit" className="panel-icon" />
        ) : (
          <ThumbUpOffAltIcon fontSize="inherit" className="panel-icon" />
        ),
    },
    {
      onClick: () => handleLikeDislike("dislike"),
      icon:
        likeStatus === "dislike" ? (
          <ThumbDownAltIcon fontSize="inherit" className="panel-icon" />
        ) : (
          <ThumbDownOffAltIcon fontSize="inherit" className="panel-icon" />
        ),
    },
    {
      onClick: openCommentModal,
      icon: <CommentIcon fontSize="inherit" className="panel-icon" />,
    },
  ];

  return (
    <div className="feedback-buttons">
      {actions.map((action, index) => (
        <div
          onClick={action.onClick}
          className="action-button"
          key={`${sectionData?.module}-${index}`}
        >
          {action.icon}
        </div>
      ))}
    </div>
  );
};

export default FeedbackButtons;
