import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Vulnerabilities2/Vulnerabilities.scss';
import Tab from "../../components/Tab/Tab";
import Overview from "./Overview/Overview";
import {useNavigate} from "react-router-dom";
import Vulnerability from "./Vulnerabilities/Vulnerabilities";
import BetaIcon from "../../assets/icons/beta2.svg";
import AssetsWithVulnerabilities from "./AssetsWithVulnerabilities/AssetsWithVulnerabilities";
import UserData from "../../store/User/UserData";
import {trackPageView} from "../../util/analytics";
import GlobalState from "../../store/GlobalState/GlobalState";
import TogglePage from "./TogglePage";
import {vulnTabsTooltips} from "./config/tooltips";
import Tooltip from "../../components/Tooltip/Tooltip";

const Vulnerabilities2 = () => {
  const [userData] = useContext(UserData);
  const [azState, setAzState] = useContext(GlobalState);
  const [currTab, setCurrTab] = useState('overview');
  const navigate = useNavigate();
  const tabs = [
    {
      display: `<span class="tab-total"><img class="beta-icon" src=${BetaIcon} alt="beta" /></span> OVERVIEW`,
      configName: "overview"
    },
    {display: `VULNERABILITIES`, configName: "vulnerabilities"},
    {display: `ASSETS WITH VULNERABILITIES`, configName: "assets"},
  ];

  const tabComponents = {
    overview: <Overview setCurrTab={setCurrTab}/>,
    vulnerabilities: <Vulnerability setCurrTab={setCurrTab}/>,
    assets: <AssetsWithVulnerabilities setCurrTab={setCurrTab}/>
  };

  const toOldVulnerabilitiesPage = () => {
    setAzState(state => ({...state, vulnerabilitiesPage: 1}));
    navigate("/risk-management/vulnerabilities", {replace: true, state: null});
  };

  useEffect(() => {
    if (azState?.vulnerabilitiesPage === 1) navigate("/risk-management/vulnerabilities2", {replace: true, state: null});
    // eslint-disable-next-line
  }, [])

  useEffect(() => trackPageView("Vulnerabilities 2.0", userData, azState),
    [azState, userData, azState.reactGaInitializedState]);

  return (
    <div className={"vulnerabilities-container"}>
      <div className={"vulnerability-tabs"}>
        <div className={"first-tab"}/>
        {tabs.map(tab =>
          vulnTabsTooltips[tab.configName] ?
            <Tooltip
              content={vulnTabsTooltips[tab.configName]}
              type={"vuln-tabs"}
              className={"title-tooltip"}
              direction={"bottom"}
              disableTimeout
            >
              <Tab
                onClick={() => {
                  navigate('/risk-management/vulnerabilities2', {replace: true, state: null});
                  setCurrTab(tab.configName)
                }}
                key={tab.configName}
                data={tab}
                selected={tab.configName === currTab}
              />
            </Tooltip> : <Tab
              onClick={() => {
                navigate('/risk-management/vulnerabilities2', {replace: true, state: null});
                setCurrTab(tab.configName)
              }}
              key={tab.configName}
              data={tab}
              selected={tab.configName === currTab}
            />
        )}
        <div className={"last-tab"}/>
        <TogglePage onClick={toOldVulnerabilitiesPage} defaultChecked={true}/>
      </div>
      {tabComponents[currTab] || null}
    </div>
  );
}

export default Vulnerabilities2;
