import O365Icon from "../../../assets/logos/o365.svg";
import React from "react";

export const newClientId = '196d3a1e-9a25-423f-8ca7-ecda6a15296b'

export const cloudInputConfigs = {
  AWSCloudTrail: {
    addAccountInputConfig: [
      {
        label: "AWS Account ID",
        value: "aws_account_id",
        tooltip:
          "A unique 12-digit identifier for your AWS account, used to manage resources, control access, track billing, and enable cross-account operations within the AWS ecosystem.",
      },
      {
        label: "S3 bucket name",
        value: "bucket",
        tooltip:
          "A unique name given to an Amazon S3 bucket, used to store and organize data within AWS. It must be globally unique and follow specific naming conventions.",
      },
      {
        label: "Prefix path",
        value: "prefix",
        tooltip:
          "A folder-like structure within an Amazon S3 bucket that helps organize and categorize objects. It is a part of the object's key and aids in efficient data management and retrieval.",
      },
      {
        label: "Access key ID",
        value: "access_key",
        tooltip:
          "A unique identifier associated with an access key. Access keys are used to securely authenticate your requests when interacting with AWS services via the AWS Command Line Interface (CLI), SDKs (Software Development Kits), or API (Application Programming Interface).",
      },
      {
        label: "Secret access key",
        value: "secret_key",
        secret: true,
        tooltip:
          "A confidential credential used alongside the Access Key ID to securely authenticate and access AWS resources.",
      },
    ],
    editAccountInputConfig: [
      {
        label: "AWS Account ID",
        value: "aws_account_id",
        readOnly: true,
        tooltip:
          "A unique 12-digit identifier for your AWS account, used to manage resources, control access, track billing, and enable cross-account operations within the AWS ecosystem.",
      },
      {
        label: "S3 bucket name",
        value: "bucket",
        tooltip:
          "A unique name given to an Amazon S3 bucket, used to store and organize data within AWS. It must be globally unique and follow specific naming conventions.",
      },
      {
        label: "Prefix path",
        value: "prefix",
        tooltip:
          "A folder-like structure within an Amazon S3 bucket that helps organize and categorize objects. It is a part of the object's key and aids in efficient data management and retrieval.",
      },
      {
        label: "Access key ID",
        value: "access_key",
        tooltip:
          "A unique identifier associated with an access key. Access keys are used to securely authenticate your requests when interacting with AWS services via the AWS Command Line Interface (CLI), SDKs (Software Development Kits), or API (Application Programming Interface).",
      },
      {
        label: "Secret access key",
        value: "secret_key",
        secret: true,
        tooltip:
          "A confidential credential used alongside the Access Key ID to securely authenticate and access AWS resources.",
      },
    ],
  },

  GoogleWorkspace: {
    addAccountInputConfig: [
      {
        label: "Google admin email",
        value: "sub_email",
        isEmail: true,
        tooltip:
          "The primary email address that is associated with a Google Workspace (formerly G Suite) administrator account, granting administrative privileges and access to manage various settings and services within the organization's Google Workspace domain.",
      },
      {
        label: "Private key JSON file content",
        value: "sa_info",
        secret: true,
        parseJSON: true,
        tooltip:
          "A JSON-formatted file that contains a private key used for secure authentication and authorization with Google Workspace APIs.",
      },
    ],
    editAccountInputConfig: [
      {
        label: "Google admin email",
        value: "sub_email",
        isEmail: true,
        tooltip:
          "The primary email address that is associated with a Google Workspace (formerly G Suite) administrator account, granting administrative privileges and access to manage various settings and services within the organization's Google Workspace domain.",
      },
      {
        label: "Private key JSON file content",
        value: "sa_info",
        secret: true,
        parseJSON: true,
        tooltip:
          "A JSON-formatted file that contains a private key used for secure authentication and authorization with Google Workspace APIs.",
      },
    ],
  },

  O365: {
    addAccountInputConfig: [
      {
        label: "Content Type",
        value: "content_type",
        tooltip: "A select list of supported Windows events to be recorded",
        multiselect: [
          {
            label: "Azure Active Directory",
            value: "Audit.AzureActiveDirectory",
          },
          {label: "Exchange", value: "Audit.Exchange"},
          {label: "General", value: "Audit.General"},
          {label: "Sharepoint", value: "Audit.Sharepoint"},
          // { label: "DLP All", value: "DLP.ALL" } TODO: We want to explore this option by first enabling it for a few customers silently
        ],
      },
      {
        label: "Tenant ID",
        value: "tenant_id",
        tooltip:
          "A unique identifier assigned to each organization or company using Microsoft Office 365 or Azure services. It is used for authentication, authorization, and resource management within the organization's O365 and Azure environments.",
      },
    ],
    editAccountInputConfig: [
      {
        label: "Content Type",
        value: "content_type",
        tooltip: "A select list of supported Windows events to be recorded",
        multiselect: [
          {
            label: "Azure Active Directory",
            value: "Audit.AzureActiveDirectory",
          },
          {label: "Exchange", value: "Audit.Exchange"},
          {label: "General", value: "Audit.General"},
          {label: "Sharepoint", value: "Audit.Sharepoint"},
          // { label: "DLP All", value: "DLP.ALL" } TODO: We want to explore this option by first enabling it for a few customers silently
        ],
      },
      {
        label: "Tenant ID",
        value: "tenant_id",
        tooltip:
          "A unique identifier assigned to each organization or company using Microsoft Office 365 or Azure services. It is used for authentication, authorization, and resource management within the organization's O365 and Azure environments.",
      },
    ],
    responseConfigOptions: [
      {
        "name": "ON_DEMAND_RESPONSE",
        "description": "ActZero's infrastructure will continuously monitor for potential threats. In this mode, administrators will be prompted via a notification to select a desired response action in the portal.",
        "value": "On-Demand (Default)"
      },
      {
        "name": "AUTO_RESPONSE",
        "value": "Auto",
        "description": "ActZero's infrastructure will continuously monitor for potential threats. If a threat is detected, it will take immediate action by disabling the user account, revoking all active sessions, resetting the account password and any MFA registration, and notifying the administrator."
      }
    ]
  },

  SalesForce: {
    authenticationType: [
        {
          name: "certificate",
          value: "Certificate",
          options: ['certificate_file', 'private_key', 'jwt_user', 'consumer_key']
        },
        {
          name: "client_id",
          value: "Client ID",
          options: ['username', 'password', 'client_id', 'client_secret', 'security_token']
        }
    ],

    addAccountInputConfig: [
      {
        label: "Username",
        value: "username",
        isEmail: true,
        tooltip:
          "The unique identifier or email address associated with a user's Salesforce account used for authentication and login purposes.",
      },
      {
        label: "Password",
        value: "password",
        secret: true,
        tooltip:
          "The confidential authentication credential used in combination with the username to access a Salesforce account securely.",
      },
      {
        label: "Client ID",
        value: "client_id",
        tooltip:
          "A unique identifier assigned to an application or integration within Salesforce, used for authentication and authorization when accessing Salesforce APIs or services.",
      },
      {
        label: "Client secret",
        value: "client_secret",
        secret: true,
        tooltip:
          "A confidential authentication credential associated with the client ID, used in OAuth authentication flows to securely verify the identity of an integrated application or service.",
      },
      {
        label: "Security token",
        value: "security_token",
        secret: true,
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
      {
        label: "Certificate file",
        value: "certificate_file",
        upload: true,
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
      {
        label: "Private key file",
        value: "private_key",
        upload: true,
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
      {
        label: "Consumer key",
        value: "consumer_key",
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
      {
        label: "JWT User",
        value: "jwt_user",
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
    ],
    editAccountInputConfig: [
      {
        label: "Username",
        value: "username",
        tooltip:
          "The unique identifier or email address associated with a user's Salesforce account used for authentication and login purposes.",
      },
      {
        label: "Password",
        value: "password",
        secret: true,
        tooltip:
          "The confidential authentication credential used in combination with the username to access a Salesforce account securely.",
      },
      {
        label: "Client ID",
        value: "client_id",
        tooltip:
          "A unique identifier assigned to an application or integration within Salesforce, used for authentication and authorization when accessing Salesforce APIs or services.",
      },
      {
        label: "Client secret",
        value: "client_secret",
        secret: true,
        tooltip:
          "A confidential authentication credential associated with the client ID, used in OAuth authentication flows to securely verify the identity of an integrated application or service.",
      },
      {
        label: "Security token",
        value: "security_token",
        secret: true,
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
            {
        label: "Certificate file",
        value: "certificate_file",
        upload: true,
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
      {
        label: "Private key file",
        value: "private_key",
        upload: true,
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
      {
        label: "Consumer key",
        value: "consumer_key",
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
      {
        label: "JWT User",
        value: "jwt_user",
        tooltip:
          "Authentication credential in Salesforce used to enhance account security.",
      },
    ],
  },

  OKTA: {
    addAccountInputConfig: [
      {
        label: "Hostname",
        value: "hostname",
        domain: ".okta.com",
        tooltip:
          "The domain or URL of the Okta service used for user authentication and identity management within an organization's Okta environment.",
      },
      {
        label: "Auth token key",
        value: "auth_token_key",
        secret: true,
        tooltip:
          "A unique authentication token key issued by Okta that provides authorized access to Okta's APIs and services.",
      },
    ],
    editAccountInputConfig: [
      {
        label: "Hostname",
        value: "hostname",
        domain: ".okta.com",
        readOnly: true,
        tooltip:
          "The domain or URL of the Okta service used for user authentication and identity management within an organization's Okta environment.",
      },
      {
        label: "Auth token key",
        value: "auth_token_key",
        secret: true,
        tooltip:
          "A unique authentication token key issued by Okta that provides authorized access to Okta's APIs and services.",
      },
    ],
  },
};

export const instructionLinks = {
  AWSCloudTrail:
    "https://docs.actzero.ai/cloud-connections/connect-aws-cloudtrail/",
  GoogleWorkspace:
    "https://docs.actzero.ai/cloud-connections/connect-google-workspace/",
  O365: "https://docs.actzero.ai/cloud-connections/connect-O365/",
  SalesForce: "https://docs.actzero.ai/cloud-connections/connect-sfdc/",
  OKTA: "https://docs.actzero.ai/cloud-connections/connect-okta/",
};

export const defaultInputValues = {
  O365: {
    content_type: [
      "Audit.AzureActiveDirectory",
      "Audit.Exchange",
      "Audit.General",
      "Audit.Sharepoint",
    ],
  },
};

export const instructions = {
  AWSCloudTrail: {
    text: "To enable ActZero to monitor your AWS environment, follow these instructions to set up an AWS CloudTrail integration.",
    inputs: [
      "AWS Account ID",
      "S3 Bucket Name",
      "S3 Prefix Path",
      "Access Key ID",
      "Secret Access Key",
    ],
  },
  GoogleWorkspace: {
    text: "To enable ActZero to monitor your Google Workspace (formerly known as G Suite accounts) environment, follow these instructions to set up the integration.",
    inputs: ["Google admin email", "Private key JSON "],
  },
  O365: {
    text: "To enable ActZero to monitor your MS O365 and Azure AD endpoints, follow these instructions to set up the integration.",
    inputs: ["Tenant ID"],
  },
  SalesForce: {
    text: "To enable ActZero to monitor your Salesforce endpoints, follow these instructions to set up the integration.",
    inputs: [
      "Username",
      "Password",
      "Client ID",
      "Client secret",
      "Security Token",
    ],
  },
  OKTA: {
    text: "To enable ActZero to monitor your Okta logs, follow these instructions to set up the integration.",
    inputs: [
      "Okta Hostname (<Your Company Domain>.okta.com)",
      "Auth token key",
    ],
  },
};

export const customElements = {
  O365: (
    <p>To grant <i>ActZero O365 MDR App</i> Access,
      <b><a
        className={"clickable-link microsoft-login"}
        target="_blank"
        rel="noopener noreferrer"
        href={
          "https://login.microsoftonline.com/organizations/v2.0/adminconsent?response_type=code&scope=196d3a1e-9a25-423f-8ca7-ecda6a15296b%2F.default&resource=https%3A%2F%2Fmanage.office.com&client_id=196d3a1e-9a25-423f-8ca7-ecda6a15296b"
        }
      >click here
      <img className={"o365-icon"} src={O365Icon} alt={"O365"}/>»
      </a></b>
    </p>
  ),
};

export const defaultTabOptions = [
  {display: "AWS", configName: "AWSCloudTrail"},
  {display: "O365", configName: "O365"},
  {display: "G Suite", configName: "GoogleWorkspace"},
  {display: "Salesforce", configName: "SalesForce"},
  {display: "Okta", configName: "OKTA"}
]

export const watchguardTabOptions = [
  {display: "O365", configName: "O365"},
]

export const defaultTabMap = {
  aws: "AWSCloudTrail",
  gsuite: "GoogleWorkspace",
  salesforce: "SalesForce",
  o365: "O365",
  okta: "OKTA"
}

export const watchguardTabMap = {
  o365: "O365",
}
