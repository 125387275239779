import AndroidIcon from "../../../config/icons/android-logo.svg";
import IosIcon from "../../../config/icons/apple-logo.svg";

export const defaultInputs = { first_name: "", last_name: "", email: "" };

export const iconLinks = [
  {
    className: "ios",
    href: "https://itunes.apple.com/app/zimperium-zips/id1030924459",
    icon: IosIcon,
  },
  {
    className: "android",
    href: "https://play.google.com/store/apps/details?id=com.zimperium.zips",
    icon: AndroidIcon,
  },
];

export const addInputsConfig = [
  { column: "first_name", placeholder: "FIRST NAME" },
  { column: "last_name", placeholder: "LAST NAME" },
  { column: "email", placeholder: "EMAIL", className: "contact" },
];