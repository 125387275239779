import React, {useState} from "react";
import "../../../../styles/pages/Onboarding/Cloud/AccountRow.scss";
import {Loader} from "../../../../components/Loader/Loader";
import IconButton from "@mui/material/IconButton";
import TrashIcon from "../../../../assets/icons/trash-icon.svg";
import EditIcon from "../../../../assets/icons/edit-icon.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import Checkmark from "../../../../assets/icons/checkmark_done.svg";
import Button from "../../../../components/Button/Button";

const rowButtonConfig = [
  {action: "edit", tooltip: "Edit", icon: EditIcon},
  {action: "delete", tooltip: "Delete", icon: TrashIcon},
];

const AccountRow = ({
                      account,
                      disableTestButton,
                      testAccountHandler,
                      setModal,
                      setErrors
                    }) => {
  const [testLoading, setTestLoading] = useState(false);
  const [testResults, setTestResults] = useState({});

  const testAccount = async () => {  // Test button makes an API call to validate the account connection
    setTestLoading(true);
    setTestResults({});
    let succeeded = await testAccountHandler(account.id, setErrors);
    setTestResults(succeeded);
    setTestLoading(false);
  }

  return (
    <tr className="cloud-table-row">
      <td className="cloud-table-cell">
        <span className="row-name">{account.connector_name}</span>
        <div className="table-icon-container">
          {!disableTestButton &&
          (testLoading ? (<div className="loader-container"><Loader/></div>) :
            (<>
              <div className="feedback-icon">
                {testResults.success && (<img className="success-icon" src={Checkmark} alt="Yes"/>)}
              </div>
              <span className="test-error">
                  {testResults.success === false && (
                    <>
                      <ErrorIcon className="err-icon"/> {testResults.error ? testResults.error : ""}
                    </>
                  )}
                </span>
                <Button className={"account-btn border-btn"} disabled={false} text={'TEST'} onClick={() => testAccount()}/>
            </>
          ))}
          {rowButtonConfig.map((btn, i) => {
            return (
              <Tooltip
                content={btn.tooltip}
                className={`${btn.action}-tooltip`}
                direction={"right"}
                key={i}
              >
                <IconButton
                  className={`table-icon ${btn.action}`}
                  onClick={() =>
                    setModal({action: btn.action, data: account})
                  }
                >
                  <img src={btn.icon} alt={btn.tooltip}/>
                </IconButton>
              </Tooltip>
            );
          })}
        </div>
      </td>
    </tr>
  );
};

export default AccountRow;
