import React, {useState} from "react";
import GearIcon from "../assets/icons/gear-icon.svg";
import {rootToolsMenuConfig, toolsMenuConfig} from "./config/navigationconfig";
import {isRoot} from "../util/userControl"
import DropdownNav from "./DropdownNav"

const getMenuConfig = (userData) => {
  if (isRoot(userData?.userType)) {
    return userData?.tenant === "actzero-parent"
      ? rootToolsMenuConfig
      : userData?.tenant === "actzero"
        ? [
          ...toolsMenuConfig,
          // TODO: Add this back when we're ready to release feature
          // { path: "/adhoc-investigation", menuText: "ADHOC INVESTIGATION" },
        ]
        : toolsMenuConfig;
  }
  return toolsMenuConfig;
};

const ToolsMenu = ({
                     activeDropdown,
                     setActiveDropdown,
                     activeNavItem,
                     setActiveNavItem,
                     handleClickTrack,
                     userData
                   }) => {
  const [toolsMenu] = useState(getMenuConfig(userData));

  return (
    <div className="tools-container">
        <span
          onClick={() => activeDropdown === "tools" ? setActiveDropdown("") : setActiveDropdown("tools")}
          className={"tools-btn " + (activeNavItem === "tools" ? "is-active " : "") +
          (activeDropdown === "tools" ? " active-dropdown" : "")
          }
          onMouseOver={() => setActiveDropdown("tools")}
        >
          <img className="gear-icon" alt="Tools" src={GearIcon}/>
        </span>
      <div
        className="tools-dropdown"
        onMouseLeave={() => setActiveDropdown("")}
      >
        {activeDropdown === "tools" && (
          <DropdownNav
            setActiveDropdown={setActiveDropdown}
            data={toolsMenu}
            indent={45}
            activeNavItem={activeNavItem}
            setActiveNavItem={setActiveNavItem}
            handleClickTrack={handleClickTrack}
            activeDropdown={activeDropdown}
            className="tools-btn"
            disableMDRLiteAndMobile={() => {
            }}
          />
        )}
      </div>
    </div>
  )
}

export default ToolsMenu;
