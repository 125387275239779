import React, { useState, useContext } from "react";
import { createReport } from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import LoaderButton from "../../components/Button/LoaderButton";
import ErrorIcon from "@mui/icons-material/Error";
import { captureSentryError } from "../../util/sentry";

const GenerateReportButton = () => {
  const [userData] = useContext(UserData);
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState(false);
  const [postSuccess, setPostSuccess] = useState("");
  const isWatchguard = userData?.tenantConfig?.is_watchguard

  const generateReport = async () => {
    setLoading(true);
    try {
      setPostError(false);
      await createReport(userData?.tenant);
      setPostSuccess("Report is being created. Please check back in 5 minutes.");
    } catch (e) {
      captureSentryError(e, userData, "POST reports API in GenerateReportButton.js");
      setPostError(true);
    }
    setLoading(false);
  }

  return (
    <div className={"report-button-container"}>
      {postSuccess ? (
        <div className="success-msg-container">
          {postSuccess}
        </div>
      ) : postError ? (
        <div className="error-msg-container">
          <ErrorIcon className="err-icon" />
          {"Error generating report"}
        </div>
      ) : ( !isWatchguard &&
        <div>
          <LoaderButton
            loading={loading}
            onClick={() => generateReport()}
            text={"CREATE REPORT"}
            className="report-btn"
          />
        </div>
      )}
    </div>
  );
};

export default GenerateReportButton;
