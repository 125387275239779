export const mockWGControlsPanel = {
  "overall": {
    "completed": 110,
    "incomplete": 1104,
    "total": 1104,
    "percent_completed": 10
  },
  "tools": [
    {
      "tool": "Endpoint Protection Platform",
      "completed": 10,
      "incomplete": 57,
      "total": 57,
      "percent_completed": 20
    },
    {
      "tool": "Firewall",
      "completed": 20,
      "incomplete": 49,
      "total": 49,
      "percent_completed": 74
    },
    {
      "tool": "Managed Detection and Response",
      "completed": 70,
      "incomplete": 97,
      "total": 97,
      "percent_completed": 2
    },
    {
      "tool": "Vulnerability Scanning",
      "completed": 15,
      "incomplete": 65,
      "total": 65,
      "percent_completed": 9
    }
  ]
}

export const mockWGVulnerabilitiesMetrics = {
  "current_scan_date": "1716163200",
  "previous_scan_date": "1713571200",
  "current_scan_scanned_ips": 17408,
  "previous_scan_scanned_ips": 17414,
  "current_scan_total": 225,
  "previous_scan_total": 215,
  "current_scan_average": 1.2569832402234637,
  "previous_scan_average": 1.2285714285714286,
  "current_scan_ips": 179,
  "previous_scan_ips": 175,
  "percent_change_from_last_scan": 4.651162790697675,
  "severities": {
    "Critical": {
      "current": 0,
      "previous": 0
    },
    "High": {
      "current": 2,
      "previous": 0
    },
    "Medium": {
      "current": 194,
      "previous": 194
    },
    "Low": {
      "current": 29,
      "previous": 21
    }
  }
}

export const mockWGVulnerabilitiesTrends = {
  "top_hosts": [
    {
      "123.12.123": 15
    },
    {
      "23.12.123": 9
    },
    {
      "123.12.123": 9
    },
    {
      "123.12.123": 9
    },
    {
      "23.12.123": 9
    }
  ],
  "top_vulnerabilities": [
    {
      "CVE-123": 363
    },
    {
      "SSH Weak Key Exchange Algorithms Enabled": 50
    },
    {
      "TLS Version 1.1 Protocol Deprecated": 45
    },
    {
      "TLS Version 1.0 Protocol Detection": 31
    },
    {
      "HSTS Missing From HTTPS Server": 23
    }
  ],
  "severity_trend": [
    {
      "date": "1709251200",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1709251200",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1709251200",
      "severity": "Medium",
      "count": 170
    },
    {
      "date": "1709251200",
      "severity": "Low",
      "count": 20
    },
    {
      "date": "1711929600",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1711929600",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1711929600",
      "severity": "Medium",
      "count": 194
    },
    {
      "date": "1711929600",
      "severity": "Low",
      "count": 21
    },
    {
      "date": "1714521600",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1714521600",
      "severity": "High",
      "count": 2
    },
    {
      "date": "1714521600",
      "severity": "Medium",
      "count": 194
    },
    {
      "date": "1714521600",
      "severity": "Low",
      "count": 29
    },
    {
      "date": "1717200000",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1717200000",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1717200000",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1717200000",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1719792000",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1719792000",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1719792000",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1719792000",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1722470400",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1722470400",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1722470400",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1722470400",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1725148800",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1725148800",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1725148800",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1725148800",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1727740800",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1727740800",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1727740800",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1727740800",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1730419200",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1730419200",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1730419200",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1730419200",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1733011200",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1733011200",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1733011200",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1733011200",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1735689600",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1735689600",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1735689600",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1735689600",
      "severity": "Low",
      "count": 0
    },
    {
      "date": "1738368000",
      "severity": "Critical",
      "count": 0
    },
    {
      "date": "1738368000",
      "severity": "High",
      "count": 0
    },
    {
      "date": "1738368000",
      "severity": "Medium",
      "count": 0
    },
    {
      "date": "1738368000",
      "severity": "Low",
      "count": 0
    }
  ]
}
