import React, {useContext, useRef} from "react";
import {useNavigate} from "react-router-dom";
import UserData from "../store/User/UserData";
import {handleSignOut} from "../util/handleSignOut";
import '../styles/navigation/UserMenu.scss';
import {useOnClickOutside} from "../util/clickOutside";

const UserMenu = ({close, userMenuRef}) => {
  const [userData] = useContext(UserData);
  const navigate = useNavigate();
  const isWatchguard = userData?.tenantConfig?.is_watchguard

  let modalRef = useRef(null)

  useOnClickOutside((e) => {
    if (!modalRef.current.contains(e.target) && !userMenuRef.current.contains(e.target)) {
      close(false);
    }
  }, modalRef);

  const userMenuConfig = [
    {text: userData?.prettyEmail},
    {text: "RESET PASSWORD", function: () => navigate("/preferences")},
    {text: "SIGN OUT", function: () => handleSignOut(userData?.tenantConfig?.is_watchguard)},
  ];

  const wgUserMenuConfig = [
    {text: userData?.prettyEmail},
  ];

  return (
    <div className="user-menu modal" ref={modalRef}>
      <div className="user-menu-container modal">
        <div className={"user-icon modal"}>
          <span>{userData?.prettyEmail ? userData?.prettyEmail.substring(0, 1).toUpperCase() : "A"}</span>
        </div>
      </div>
      <div className={"menu-btns"}>
        {(isWatchguard ? wgUserMenuConfig : userMenuConfig).map((opt, i) =>
          i === 0 ? <p className={"email"} key={opt.text} onClick={opt.function}>{opt.text}</p> :
            <p className="user-menu-btn" onClick={opt.function}>{opt.text}</p>
        )}
      </div>
    </div>
  );
};

export default UserMenu;
