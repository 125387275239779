import React, {useContext} from 'react';
import LeftDashboard from "./LeftDashboard";
import RightDashboard from "./RightDashboard";
import '../../styles/pages/MultiTenantDashboard/MultiTenantDashboard.scss';
import UserData from "../../store/User/UserData";

const MultiTenantDashboard = () => {
  const [userData] = useContext(UserData);
  const isWatchguard = userData?.tenantConfig?.is_watchguard
  return (
    <div className={"multitenant-dashboard-container" + (isWatchguard ? ' watchguard' : '')}>
      {!isWatchguard ? <>
        <LeftDashboard/>
        <RightDashboard/>
      </> : <>
        <p className={"coming-soon-text"}>Coming Soon - Partner Dashboard</p>
        <p className={"coming-soon-text sub"}>To get started, select a managed account from the drop-down menu in the
          upper-right corner.</p>
      </>
      }
    </div>
  )
}

export default MultiTenantDashboard;
