import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/pages/Onboarding/UploadModal.scss";
import { FileUploader } from "react-drag-drop-files";
import UploadIcon from "../../assets/icons/file-upload-icon.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import _ from "lodash";
import { Loader } from "../../components/Loader/Loader";
import { trackClick } from "../../util/analytics";
import { captureSentryError } from "../../util/sentry";
import Button from "../../components/Button/Button";

const fileTypes = ["CSV", "CERT", "PEM", "DER", "KEY"];
const fileTypesMIME = ["text/plain", "text/csv", "application/json", "application/x-x509-ca-cert", "application/x-iwork-keynote-sffkey"];
const errorMessage =
  "Error uploading the CSV. Please try again. If the problem persists, please reach out to customer-support@actzero.ai.";

export default function UploadModal(props) {
  const { isOpen, setOpen, userData, uploadFunction, refreshData, sampleCSV } =
    props;
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [incompatibleFileMsg, setIncompatibleFileMsg] = useState(false);

  const handleClose = () => {
    setFiles([]);
    setErrors([]);
    setOpen(false);
    setIncompatibleFileMsg(false);
    setLoading(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      let fileErrors = [];
      for (let i in files) {
        const results = await uploadFunction(
          userData?.tenant,
          files[i],
          "text/csv"
        );
        const failedUsers = results.failed_users
          ? results.failed_users
          : results
              .filter((r) => !r.success)
              .map((r) => {
                return { email: r.email, reason: r.error };
              });
        fileErrors = [...fileErrors, ...failedUsers];
        trackClick(userData, `uploaded file ${files[i].name}`, "click");
      }
      setErrors(fileErrors.map((e) => `${e.email} - ${e.reason}`));
      fileErrors.length ? setLoading(false) : handleClose();
    } catch (e) {
      setErrors(errorMessage);
      setLoading(false);
      captureSentryError(e, userData, "uploadFunction API in onboarding/UploadModal.js");
    }
    refreshData();
  };

  const addFiles = (files) => {
    const filtered = [...files].filter((f) => {
      if (!fileTypesMIME.includes(f.type)) {
        setIncompatibleFileMsg(true);
        return false;
      }
      return true;
    });
    setFiles((prevState) => [...prevState, ...filtered]);
  };

  const deleteFile = (file) => {
    setFiles((prevState) => prevState.filter((f) => f.name !== file.name));
  };

  const downloadSampleFile = () => {
    let alink = document.createElement("a");
    alink.href = sampleCSV;
    alink.download = "AZ_Sample_CSV_Format.csv";
    alink.click();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableAutoFocus
      disableScrollLock
    >
      <Box className="uploadCSV-modal-container" sx={{ boxShadow: 24 }}>
        <div className="title-container">
          <span className="title">File upload</span>
        </div>
        {loading ? (
          <Loader />
        ) : errors.length ? (
          <div className="error-msg">
            {Array.isArray(errors) ? (
              <ul>
                <li>There was a problem uploading the file(s):</li>
                {errors.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
            ) : (
              errors
            )}
          </div>
        ) : (
          <div className="upload-content-container">
            {incompatibleFileMsg && (
              <span className="incompatible-msg">
                {"Incompatible file type added."} <br />{" "}
                {"Accepted file types: " + fileTypes.join(", ") + "."}
              </span>
            )}
            <FileUploader
              handleChange={addFiles}
              name="file"
              types={fileTypes}
              hoverTitle=" "
              multiple
            >
              <div className="dragdrop-area">
                <div className="dragdrop-content">
                  <img className="upload-icon" src={UploadIcon} alt="Upload" />
                  <span className="dragdrop-text">
                    Drag and drop CSV file to upload
                  </span>
                </div>
              </div>
            </FileUploader>
            <div className="files-btn-container">
              <span className="upload-text">{"or"}</span>
              <IconButton>
                <Button
                  className={"files-button border-btn"}
                  disabled={false}
                  text={'CHOOSE FILES'}
                />
                <input
                  type="file"
                  id="select-files"
                  multiple
                  onChange={(e) => {
                    addFiles(e.target.files);
                    e.target.value = null;
                  }}
                  accept={fileTypesMIME.join()}
                />
              </IconButton>
            </div>
            <div className="file-list-container">
              <ul className="file-list">
                {files.map((f) => {
                  return (
                    <li className="file-list-item" key={_.uniqueId()}>
                      {f.name}
                      <button onClick={() => deleteFile(f)}>x</button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="download-sample">
              <IconButton
                className="download-icon"
                onClick={() => downloadSampleFile()}
              >
                <img src={DownloadIcon} alt="" />
              </IconButton>
              <span>Sample CSV</span>
            </div>
            <div className="upload-btn-container">
              <Button
                className={"files-button border-btn"}
                disabled={!files.length}
                text={'UPLOAD'}
                onClick={() => handleUpload()}
              />
            </div>
          </div>
        )}
        <IconButton
          aria-label="Close"
          onClick={handleClose}
          className="close-button-container"
        >
          <CloseIcon className="close-icon" fontSize="small" />
        </IconButton>
      </Box>
    </Modal>
  );
}
