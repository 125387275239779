import React from "react";
import '../../styles/pages/Watchguard/MDRLicense.scss';
import ManagedServicesIcon from "./config/icons/managed-services-logo.svg";
import AZWGLogo from '../../assets/logos/az-wg-logo.svg';

const MDRLicense = () => {
  return (
    <div className={"mdr-license-container flex"}>
      <img className={"az-wg-logo"} src={AZWGLogo} alt={"Actzero-WatchGuard"}/>
      <img className={"managed-services-icon"} src={ManagedServicesIcon} alt={"managed services"}/>
      <div>
        <p className={"page-title mdr-title"}>Almost There!</p>
        <p>Enhance your security with WatchGuard’s MDR service — 24/7 monitoring and protection.</p>
        <p>Looks like there’s no active license on your account yet. Talk to your account manager to get started.</p>
      </div>
    </div>
  );
};

export default MDRLicense;
