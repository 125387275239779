export const modules = [
  { label: "Reputation", value: "reputation" },
  { label: "GeoIP", value: "geoip" },
  { label: "Whois", value: "whois" },
  { label: "Is Known Endpoint", value: "is_known_endpoint" },
  { label: "Is IP in Network", value: "is_ip_in_network" },
  { label: "ACL", value: "acl" },
  { label: "SLD", value: "sld" },
  { label: "Travel Notifications", value: "travel_notifications" },
  { label: "Related Cases", value: "related_cases" },
  { label: "Links", value: "links" },
  { label: "Logins", value: "logins" },
];

export const startPoints = [
  {
    label: "IP Investigation",
    values: [
      "geoip",
      "reputation",
      "whois",
      "is_known_endpoint",
      "is_ip_in_network",
    ],
    inputLabel: "Enter an IP Address",
    inputValue: "ip",
    agent: "ip",
    expectedFiles: 3 // Modules, summary, traceID
  },
  {
    label: "O365 User Investigation",
    values: [
      "acl",
      "sld",
      "travel_notifications",
      "related_cases",
      "links",
      "logins",
    ],
    inputLabel: "Enter a user ID",
    inputValue: "user_id",
    agent: "o365_user",
    expectedFiles: 4 // Modules, summary, timeline, traceID
  },
];

export const disclaimer = "Note: This feature is currently experimental. Dates and times may be inaccurate, and some data may be incomplete. Please verify all information before sharing it with customers."
