import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Connections/Log.scss';
import {mapIcons} from "./config/iconMap";
import ConnectionsLineChart from "./ConnectionsLineChart";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {getLogConnections} from "../../api/ApiClient";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import {abbreviateNumber, formatTimeAgo, convertDataSize, getDataUnit} from "../../util/format";
import {getTrendDifference} from "./config/trend";
import DisabledChart from "./DisabledChart";
import {hasAdminAccess} from '../../util/userControl';
import MailButton from "../../components/MailButton/MailButton";
import NotFound from "../../navigation/NotFound";
import {addContractedLine} from "./config/helper";
import { captureSentryError } from '../../util/sentry';

const LogConnection = ({onInvestigationClick, isMDRLite, isMobileOnly, createEmail}) => {
  const [userData] = useContext(UserData);
  const [connectionsData, setConnectionsData] = useState({})
  const [logLoading, setLogLoading] = useState(false);
  const [dataSize, setDataSize] = useState('')
  const [remainingLogUsage, setRemainingLogUsage] = useState(0)
  const [colors, setColors] = useState({});
  const [fetchError, setFetchError] = useState(false);
  const [contractedAmount, setContractedAmount] = useState(0)
  const mapValues = {
    "Contracted": {field: 'contracted_log_volume'}
  }
  useEffect(() => {
    const getConnections = async () => {
      setFetchError(false);
      setLogLoading(true)
      try {
        let connectionsResponse = await getLogConnections(userData?.tenant)
        if (!(connectionsResponse.hasOwnProperty("error"))) {
          if (connectionsResponse?.trend?.data) {
            let names = Object.keys(connectionsResponse.trend.data)
            names.forEach(name => {
              connectionsResponse.trend.data[name].length > 0 && connectionsResponse.trend.data[name].map(data => {
                data[1] = data[1] > 0 && Math.round((data[1] / Math.pow(1024, 3)) * 100) / 100
                return data
              })
            })
          }
          setDataSize(getDataUnit(connectionsResponse?.total_log_volume))
          let maxContractedVolumeSize = formatContractedVolume(connectionsResponse?.contracted_log_volume)
          let percentageUsed = (connectionsResponse?.total_log_volume / maxContractedVolumeSize) * 100
          setRemainingLogUsage(100 - percentageUsed.toFixed(0))
          let name = `Log_volume_in_GB_per_day`
          const rootStyles = getComputedStyle(document.documentElement);
          const green = rootStyles.getPropertyValue('--green').trim();
          const grey = rootStyles.getPropertyValue('--grey').trim();
          setColors({[name]: green, contracted: grey})
          connectionsResponse.trend.data[name] = connectionsResponse.trend.data[Object.keys(connectionsResponse.trend.data)[0]]
          delete connectionsResponse.trend.data[Object.keys(connectionsResponse.trend.data)[0]];
          let contracted = parseInt(connectionsResponse?.contracted_log_volume.split('-')[1])
          setContractedAmount(contracted)
          addContractedLine(contracted, connectionsResponse, 'Log_volume_in_GB_per_day')
        }
        setConnectionsData(connectionsResponse)
      } catch (e) {
        captureSentryError(e, userData, "getLogConnections API in Log.js");
        setFetchError(true);
      } finally {
        setLogLoading(false)
      }
    }
    userData?.tenant && (!isMDRLite && !isMobileOnly) && getConnections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, isMDRLite, isMobileOnly]);

  function formatContractedVolume(contractedLogVolume) {
    const regex = /(\d+)-(\d+)/
    const match = contractedLogVolume.match(regex)
    let maxSize = 0
    if (match && match.length >= 3) {
      maxSize = parseInt(match[2])
      maxSize = maxSize * 1024 * 1024 * 1024 * 30
    }
    return maxSize
  }

  return (
    <>
      {!connectionsData.hasOwnProperty("error") &&
      <tr className={"connections-row log"}>
        {!logLoading ?
          fetchError ? <NotFound isError dataError className="panel service"/>
            :
            <>
              <td className={"connection-type"}>
                <Tooltip content={tooltipMapping.serviceStatus.log} className={"title-tooltip"} direction={"right"}>
                  <p className={"is-connected" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>LOG</p>
                </Tooltip>
                {'total_log_volume' in connectionsData &&
                <p className={"count" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>
                  {convertDataSize(connectionsData.total_log_volume, 0)}
                  <span className={"size"}> {dataSize}</span>
                </p>}
                {(isMobileOnly || isMDRLite) && hasAdminAccess(userData?.role) &&
                <MailButton label={"ADD SERVICE »"} mailto={createEmail('log')}/>}
              </td>
              <td>
                <span className={"circle " + (!connectionsData?.status ? "grey" : connectionsData?.status)}/>
              </td>
              <td className={"service-icons" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>
                <img alt={'log'} src={mapIcons('log'.toLowerCase())}/>
                {remainingLogUsage < 0 &&
                getTrendDifference(remainingLogUsage, true, " more data on average than contracted for.")
                }
              </td>
              <td className={"connection-data"}>
                {Object.entries(mapValues).map((elem, index) => {
                  let field = elem[1].field
                  if (elem[0] === "Contracted" && connectionsData[field] === null) return ''
                  let count = connectionsData[field]
                  let value = field === 'last_seen' ? formatTimeAgo(connectionsData[field]) :
                    abbreviateNumber(connectionsData[field], 0)
                  let hasInvestigationLink = (elem[0] === "Investigations" && count > 0)
                  return <div
                    key={index}
                    className={"display-values " + elem[0] + (hasInvestigationLink ? ' pointer' : '')}
                  >
                    <p className={"name"}>{elem[0]}</p>
                    <p
                      className={"value " + (hasInvestigationLink ? ' link' : '')}
                      onClick={() => hasInvestigationLink && onInvestigationClick('Log')}
                    >{!isMDRLite && !isMobileOnly ? `${value} per day` : ''}</p>
                  </div>
                })}
              </td>
              <td className={"chart-container" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>
                {connectionsData?.trend?.data && (!isMobileOnly && !isMDRLite) ?
                  <ConnectionsLineChart
                    contractedAmount={contractedAmount}
                    numberFormat={".2s"}
                    displayName={'log'}
                    data={connectionsData.trend.data}
                    colors={colors}
                  /> : (isMobileOnly || isMDRLite) &&
                  <DisabledChart
                    data={[{name: 'Data', values: []}, {name: 'Contracted_Data', values: []}]}
                    colors={{Data: 'green', Contracted_Data: 'orange'}}
                  />}
              </td>
            </> : <td><Loader/></td>}
      </tr>}
    </>
  )
}

export default LogConnection;
