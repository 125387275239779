import React, {useContext, useEffect, useState} from "react";
import {getTrends} from "../../api/ApiClient";
import {captureSentryError} from "../../util/sentry";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {mapChartData} from "../../util/mapChartData";
import {colors, severitiesValues} from "./config/graph-config";
import '../../styles/pages/MultiTenantDashboard/InvestigationsBySeverity.scss';
import MultiTenantLineChart from "./components/MultiTenantLineChart";
import InvestigationsIcon from "../../assets/icons/investigationicon.svg";
import {onTenantClick} from "./config/helper";

const InvestigationsBySeverity = () => {
  const [investigationsGraphData, setInvestigationsGraphData] = useState([])
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [userData] = useContext(UserData);
  const [loading, setLoading] = useState(false);
  const [investigationsList, setInvestigationsList] = useState([])

  useEffect(() => {
    const getTrendData = async () => {
      setLoading(true)
      try {
        const data = await getTrends(userData?.tenant, 'investigations');
        mapChartData(data.severity_trend, setAxisData, setInvestigationsGraphData, severitiesValues, [])
        setInvestigationsList(data?.top_investigations)
      } catch (e) {
        captureSentryError(e, userData, 'getTrends investigation trends')
      } finally {
        setLoading(false)
      }
    }
    userData?.tenant && getTrendData()
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType])

  return (
    <div className={"multi-tenant-panel investigations"}>
      {loading ? <Loader/> : investigationsGraphData?.length > 0 &&
        <div className={"graph-container" + (investigationsGraphData?.length === 0 ? ' no-data' : '')}>
          <div className={`title-and-legend`}>
            <p className={`graph-title`}>INVESTIGATIONS BY SEVERITY
              <span className={"sub-title"}> OVER TIME, AGGREGATED</span>
            </p>
          </div>
          <div className={"flex investigations-panel"}>
            <MultiTenantLineChart
              className={"investigations-trends"}
              axisData={axisData}
              colors={colors}
              mappedGraphData={investigationsGraphData}
              width={450}
              widthOffset={.7}
              icon={InvestigationsIcon}
            />
            <div className={"panel-tables investigations"}>
              <p className={"title"}>HIGHEST NUMBER OF INVESTIGATIONS</p>
              {investigationsList?.map((item, i) =>
                <p className={"detection-value"} key={i}>
                  <span className={"label"} onClick={() => onTenantClick(item.tenant_id, '/activity/investigations')}>
                    {item.friendly_name}
                  </span>
                  <span className={"value"}>{item.count}</span>
                </p>
              )}
            </div>
          </div>
        </div>}
    </div>
  )
}
export default InvestigationsBySeverity;
