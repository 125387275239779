import "../../../styles/pages/Onboarding/Endpoints/EndpointsOnboarding.scss";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getEndpointAgentData, getEndPointConnections} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import {Loader} from "../../../components/Loader/Loader";
import {useOnClickOutside} from "../../../util/clickOutside";
import Endpoint from "./Endpoint";
import { captureSentryError } from "../../../util/sentry";

const EndpointsOnboarding = () => {
  const [userData] = useContext(UserData);
  const [endpointAgentData, setEndpointAgentData] = useState();
  const [endpointConnectionData, setEndpointConnectionData] = useState();
  const [loading, setLoading] = useState();
  const [showCrowdstrikeInfo, setShowCrowdstrikeInfo] = useState(false);
  const [stringCopied, setStringCopied] = useState(false);
  const [expandModal, setExpandModal] = useState(false);
  const [onboardingEndpoints, setOnboardingEndpoints] = useState([])
  const navigate = useNavigate();
  const isWatchguard = userData?.tenantConfig?.is_watchguard

  useEffect(() => {
    userData.tenant && getEndpointsOnboardingData();
    // eslint-disable-next-line
  }, [userData?.tenant]);

  async function getEndpointsOnboardingData() {
    setLoading(true);
    try {
      const agentData = await getEndpointAgentData(userData?.tenant);
      setEndpointAgentData(agentData);
    } catch(e){
      captureSentryError(e, userData, "getEndpointAgentData API in EndpointsOnboarding.js");
    }
    try {
      const connectionsResponse = await getEndPointConnections(userData?.tenant);
      setEndpointConnectionData({
        contracted: connectionsResponse.contracted_endpoints || 0,
        installed: Object.values(connectionsResponse.active_endpoints).reduce(
          (a, b) => a + b,
          0
        ),
      });
      let offerings = isWatchguard ? ['Defender'] : userData?.tenantConfig?.offerings?.edr
      setOnboardingEndpoints(offerings)
    } catch (e) {
      captureSentryError(e, userData, "getEndPointConnections API in EndpointsOnboarding.js");
    }
    setLoading(false);
  }

  const crowdstrikeInfoRef = useOnClickOutside(() => setShowCrowdstrikeInfo());

  return (
    <div className="endpoints-onboarding-container">
      <div className="onboarding-title">ENDPOINTS</div>
      {loading ? (
        <Loader/>
      ) : (!isWatchguard &&
        <>
          <div className="endpoints-total">
            <span className="total-number">{endpointConnectionData?.installed}</span>
            <span className="total-label">{endpointConnectionData?.installed === 1 ? 'Endpoint' : 'Endpoints'}</span>
            <span className="total-link" onClick={() => navigate("/connections/endpoints")}>{"SEE ALL »"}</span>
          </div>
        </>
      )}

      {!loading &&
      onboardingEndpoints?.map((item, i) => <Endpoint
        key={i}
        loading={loading}
        crowdstrikeInfoRef={crowdstrikeInfoRef}
        setShowCrowdstrikeInfo={setShowCrowdstrikeInfo}
        setStringCopied={setStringCopied}
        showCrowdstrikeInfo={showCrowdstrikeInfo}
        stringCopied={stringCopied}
        endpointType={item}
        endpointConnectionData={endpointConnectionData}
        endpointAgentData={endpointAgentData}
        expandModal={expandModal}
        setExpandModal={setExpandModal}
        />)}
    </div>
  );
};

export default EndpointsOnboarding;
